import React from 'react';
import RatesLineChart from './RatesLineChart.js';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getCurrencySymbolFromCode } from '../../../utils.js';

const RatesVis = ({ratesData, vscRatesLoading, vscRatesProgress, vscRatesError, currencyCode, completionValue, currentCompletions,
  datepickerRanges, orgTime,
}) => {
  return (<>
    {vscRatesLoading &&
      <div className="progress-area d-flex flex-grow-1 p-2 align-items-center">
        <div className="w-100">
          <ProgressBar className="my-auto" animated now={vscRatesProgress}/>
        </div>
      </div>}
    {vscRatesError && <div className="d-flex justify-content-center align-items-center error-msg-area"><p>{vscRatesError}</p></div>}
    {!vscRatesLoading && !vscRatesError && ratesData && <>
      <div className="completion-rate-vis">
        <RatesLineChart
          ratesData={ratesData}
          datepickerRanges={datepickerRanges}
          orgTime={orgTime}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        <div className="value-increase-container">
          <p className="mb-0 stat-label text-center">Value of 5% increase in Completions</p>
          <p className="mb-0 fs-5 fw-500 text-center">{getCurrencySymbolFromCode(currencyCode)}{((currentCompletions * 0.05) * completionValue).toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
        </div>
      </div>
    </>}
  </>);
};

export default RatesVis;
