import React, { useMemo } from 'react';
import { BarController } from 'chart.js';
import { ReactChart } from 'chartjs-react';

ReactChart.register(BarController);

const FloatingBarChart = ({formData, animateBars, maxLostValue, index,
  sortedOnValue, maxSessionCount, formUuid,
}) => {

  const data = useMemo(() => {
    const chartData = sortedOnValue === 'lost-first' ? formData?.stats.totalLostValue : formData?.stats.views.current;

    return {
      labels: ['Total'],
      datasets: [
        {
          label: sortedOnValue === 'lost-first' ? 'Lost Revenue' : 'Total Sessions',
          data: [
            chartData
          ],
          borderColor: 'white',
          borderWidth:1,
          maxBarThickness: 50,
          borderRadius: {
            topLeft: 0,
            bottomLeft: 0,
            topRight: 5,
            bottomRight: 5,
          },
          borderSkipped: false,
          backgroundColor: '#6950a5',
          minBarLength: chartData ? 3 : 0,
        },
      ]
    };
  }, [sortedOnValue, formData?.stats]);

  const config = {
    type: 'bar',
    data: data,
    options: {
      animation: {
        duration: animateBars ? 1000 : 0,
        delay: (context) => {
          let delay = 0;
          if (animateBars && context.type === 'data' && context.mode === 'default') {
            delay = context.dataIndex * 300 + (index * 500);
          }
          return delay;

        }
      },
      layout: {
        padding: 0,
      },
      maintainAspectRatio: false,
      scales: {
        x: {
          suggestedMax: sortedOnValue === 'lost-first' ? maxLostValue : maxSessionCount,
          border: {
            display: false,
          },
          stacked: true,
          title: {
            display: false,
            text: sortedOnValue === 'lost-first' ? 'Total Lost Revenue' : 'Total Session Count',
            align: 'start',
          },
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
            drawTicks: false,
          },
          beginAtZero: true,
        },
        y: {

          title: {
            display: false,
          },
          beginAtZero: false,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
            drawTicks: false,
          },
        }
      },
      indexAxis: 'y',
      plugins: {
        tooltip: {
          enabled: false
        },

        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        title: {
          display: false,
        },
      }
    },
  };

  return (<div className="floating-bar-chart">
    <ReactChart
      id={`floating-bar-chart-${formUuid}`}
      type="bar"
      data={config.data}
      options={config.options}
    /></div>
  );
};

export default FloatingBarChart;
