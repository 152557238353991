import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import InputGroup from 'react-bootstrap/InputGroup';
import MixpanelContext from 'react-mixpanel';
import { VscCheck } from "react-icons/vsc";

import api from '../../api.js';

import { orgDetailsForMixpanel, getCurrencySymbolFromCode } from '../../utils.js';

const LostRevenueCalculator = ({formUuid, formOrg, handleUpdatingFormCompletion, currencyCode, completionValue, formCompletionValueIsSet,
}) => {
  const history = useHistory();
  const mixpanel = useContext(MixpanelContext);

  const [selectedCode, setSelectedCode] = useState();
  const [inputValue, setInputValue] = useState();
  const [completionValueError, setCompletionValueError] = useState();
  const [completionValueSaved, setCompletionValueSaved] = useState();
  const [completionValueIsSaving, setCompletionValueIsSaving] = useState();
  const [completionValueReadyToSave,setCompletionValueReadyToSave] = useState();

  const handleSaveCompletion = useCallback(async ({completionValue, completionValueCurrencyCode}) => {
    try {
      setCompletionValueError(null);
      setCompletionValueIsSaving(true);

      await api.put(`/forms/${formUuid}`, {
        completionValue,
        completionValueCurrencyCode,
      });

      setCompletionValueSaved(true);
      handleUpdatingFormCompletion({uuid: formUuid, completionValue, completionValueCurrencyCode});

      mixpanel.track('Changed Completion Value', { page: 'Dashboard', ...orgDetailsForMixpanel(formOrg)});
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setCompletionValueError('Something went wrong. The value has not been saved.');
      }
    } finally {
      setCompletionValueIsSaving(false);
      setCompletionValueReadyToSave(false);
    }
  }, [handleUpdatingFormCompletion, formOrg, formUuid, history, mixpanel]);

  useEffect(() => {
    setSelectedCode(currencyCode);
  }, [currencyCode]);

  useEffect(() => {
    if (formCompletionValueIsSet) setInputValue(completionValue);
  }, [formCompletionValueIsSet, completionValue]);

  // Remove completion value message
  useEffect(() => {
    if (completionValueSaved) setTimeout(() => {setCompletionValueSaved(null);}, 4000);
  }, [completionValueSaved]);

  return (
    <div className="d-flex flex-column completion-value-input-area w-100">
      <p className="mb-0">
        <OverlayTrigger placement="top" overlay={<Popover className="dashboard-popover">
          <Popover.Body className="fs-6 text-start">
            <p className="mb-0">The lost revenue calculation helps you to quantify the benefits of running a form optimisation project and understand which areas of your form are costing you the most money.</p>
          </Popover.Body>
        </Popover>}>
          <u className="text-more-info">Lost revenue</u>
        </OverlayTrigger> is calculated using the value of a form completion.</p>
      <div className="d-inline-flex align-items-center py-1 flex-grow-1">
        <Form.Label className="mb-0 text-nowrap me-2" htmlFor="completion-value">Completion value</Form.Label>
        <InputGroup>
          <Form.Select value={selectedCode || ''} id="completion-currency" className="ms-0 currency-select" aria-label="completion value currency code"
            onChange={({target: {value}}) => {
              setSelectedCode(value);
              setCompletionValueReadyToSave(true);
            }}>
            {[{label: '£', value: 'gbp'}, {label: '$', value: 'usd'}, {label: '€', value: 'eur'}].map((opt) => (<option key={opt.value} value={opt.value}>{opt.label}</option>))}
          </Form.Select>
          <Form.Control type="number" min={0} step="1" value={inputValue ?? ''} id="completion-value" aria-label="completion value"
            onChange={({target: {value}}) => {
              setInputValue(value);
              setCompletionValueReadyToSave(true);
            }}
          />
        </InputGroup>
        {(completionValueReadyToSave && !completionValueIsSaving) &&
          <Button variant={`${completionValueReadyToSave ? 'secondary' : 'outline-secondary'}`} className="ms-2 me-0" onClick={() => {
            handleSaveCompletion({completionValueCurrencyCode: selectedCode, completionValue: inputValue});
          }}>Save</Button>}
        {completionValueSaved &&
          <Alert variant="outline-success" className="ms-2 mb-0">
            <div className="alert-svg-icon my-auto"><VscCheck size="16px"/></div>
            <p className="m-0 ps-2">Saved</p>
          </Alert>}
        {(!inputValue && !completionValueReadyToSave && !completionValueSaved) && <Alert variant="outline-info" className="w-auto ms-2 mb-0">
          <p className="m-0 text-nowrap">Default {getCurrencySymbolFromCode(currencyCode)}100</p>
        </Alert>}
      </div>
      {completionValueError && <p>{completionValueError}</p>}
    </div>
  );
};

export default LostRevenueCalculator;
