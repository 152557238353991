import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { FaCog } from "react-icons/fa";
import { VscWarning } from "react-icons/vsc";

import AppContext from '../../AppContext';
import api from '../../api';
import OrgTrackedForms from './OrgTrackedForms';

const OrgCard = ({org, differentUser}) => {
  const { currentUser, currentUserRoleInOrgs } = useContext(AppContext);
  const [orgSessionData, setOrgSessionData] = useState();
  const [orgSessionDataError, setOrgSessionDataError] = useState(null);
  const [agencyOrg, setAgencyOrg] = useState();
  const hasShopifyOrg = org.name.includes('myshopify.com');
  const [reportVisible, setReportVisible] = useState(true);

  const trackedForms = useMemo(() => org?.forms?.filter(f => f.tracked), [org?.forms]);

  const showOrgSignUpBtn = () => {
    const currentUserIsStandardInOrg = currentUserRoleInOrgs?.[org.uuid]?.name !== 'admin' ?? true;
    if (
      currentUserIsStandardInOrg ||
      !org ||
      currentUser.organisations?.length === 1 || // Don't show if they are only part of 1 org as it will show in the nav
      org?.type === 'AgencyClient') return false;

    if (org && ((org.subscriptionStatus && org.subscriptionStatus === 'canceled') ||
     (org.contractType && org.contractType === 'trial'))) return true;
  };

  // Fetch session data for the org
  useEffect(() => {
    const fetchOrgSessionStats = async (org) => {
      try {
        switch (org.contractType) {
        case 'trial':
        case 'fixed': {
          const {data: {count: sessionUsage}} = await api.get('/data/sessions/stats', {
            params: {
              organisationUuid: org.uuid,
              metric: 'count',
            },
          });
          setOrgSessionData({sessionUsage, contractType: org.contractType, sessionLimit: org.sessionLimit});

          break;
        }
        default: {
          const {data: {count: sessionUsage}} = await api.get('/data/sessions/stats', {
            params: {
              organisationUuid: org.uuid,
              metric: 'count',
              timePeriod: {start: 'now/M', end: 'now'},
            },
          });
          setOrgSessionData({sessionUsage, contractType: org.contractType, sessionLimit: org.sessionLimit});
        }}
      } catch (e) {
        setOrgSessionDataError('Oops, sorry something went wrong fetching session totals. Please try again.');
      }
    };

    // Only request org session data for orgs that have forms
    if (org.hasOwnProperty('forms') && org.forms.length > 0) fetchOrgSessionStats(org);

  }, [org]);

  useEffect(() => {
    if (org.type === 'Agency') {
      (async () => {
        try {
          const {data: { organisation } } =  await api.get(`/organisations/${org.uuid}`, {
            params: {
              include: ['clients'],
            }});

          setAgencyOrg(organisation);
        } catch (e) { /* Do nothing as this is a background update */ }
      })();
    }
  }, [org]);

  useEffect(() => {
    if(!currentUser.accountManager) setReportVisible(!!org.reportAccessEnabled);
  }, [currentUser.accountManager, org.reportAccessEnabled]);

  return (
    <Card data-testid={`org-${org.uuid}`} className={reportVisible ? '' : 'blurred-report'}>
      <Card.Body className="pb-0">
        <Row className="g-0 card-title-row justify-content-between mb-2">
          <Col className="p-0 d-flex align-items-end col-auto">
            <Card.Title as="h3">{org.type === 'Agency' ? 'Agency | ' : null}{org.name}</Card.Title>
          </Col>
          <Col className="p-0 d-inline-flex align-items-center justify-content-end col-auto">
            {orgSessionDataError && <p className="fw-light mb-0" data-testid="orgs-session-error">{orgSessionDataError}</p>}
            {orgSessionData && orgSessionData.hasOwnProperty('sessionUsage') && orgSessionData.hasOwnProperty('sessionLimit') && orgSessionData.contractType &&
              <p className="session-usage-stats mb-0">{(orgSessionData.sessionUsage).toLocaleString()} / {(orgSessionData.sessionLimit).toLocaleString()} {(orgSessionData.contractType)} allowance</p>}
            {showOrgSignUpBtn() &&
              <Link to={{pathname: `/organisations/${org.uuid}/choose-plan`, state: {organisation: {name: org.name}}}}><Button className="sign-up-btn mr-0">Sign Up</Button></Link>}
            <Link to={`/organisations/${org.uuid}/edit`}><FaCog size="20px" className="grey-icon ms-2" title="Org settings"/></Link>
          </Col>
        </Row>
        {org.forms?.filter(f => f.tracked).length > 0 &&
          <Row className="alert-row g-0">
            <Alert className="beta-alert">
              <p className="alert-text m-0">This dashboard is new. If you have any suggestions on how to improve it further we'd love to hear them at <a
                href="mailto:suggestions@zuko.io?subject=Dashboard Suggestion">suggestions@zuko.io</a>.</p>
            </Alert>
          </Row>
        }
        {org.forms?.filter(f => !f.tracked).length > 0 &&
              <Row className="g-0 alert-row">
                <Alert variant="warning" className="tracking-warning-alert">
                  <div className="alert-svg-icon my-auto d-flex"><VscWarning size="20px" className="me-2"/></div>
                  <p className="alert-text fs-6 m-0">Please review the forms where <Link to={`/organisations/${org.uuid}/edit#forms`} target="_blank">tracking has not yet started</Link>.</p>
                </Alert>
              </Row>}
        <div className="card-vis">
          {org?.type === 'AgencyClient' && org.sessionLimit === 0 &&
            <Row className="alert-row g-0 pb-2" id="users-info-alert">
              <Alert dismissible={false} variant={'warning'}>
                <div className="page-alert-svg-icon d-flex"><VscWarning size="100%"/></div>
                <p className="alert-text m-0">This client does not have a session limit allocated to it. The forms will not currently be tracking.</p>
              </Alert>
            </Row>}
          {org && org.type !== 'Agency' &&
          ((org.forms && org.forms.length <= 0) || (!org.hasOwnProperty('forms'))) && <>
            <p data-testid="no-forms-msg">No forms created yet.</p>
            {hasShopifyOrg ?
              <Alert variant="danger">
                <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                <p className="alert-text m-0">Please return to your Shopify account and click to open the the Zuko Checkout Analytics app again to restart the installation.</p>
              </Alert> :
              <Link to="/forms/new"><Button className="btn-less-padding ms-0">Add Form</Button></Link>}
          </>}
          {org?.type === 'Agency' && agencyOrg?.clients?.length > 0 && <>
            <p>View forms within your clients.</p>
            <Link to={{
              pathname: `/organisations/${org.uuid}/edit`,
              hash: '#clients'}}><Button className="btn-less-padding ms-0">Manage Clients</Button></Link>
          </>}
          {org?.type === 'Agency' && agencyOrg?.clients?.length === 0 && <p>No clients created yet, please <Link to={`/organisations/${org.uuid}/agency_clients/new`}>add a client</Link>.</p>}
          {trackedForms?.length > 0 && <OrgTrackedForms
            org={org}
            forms={trackedForms}
            differentUser={differentUser}
          />}
        </div>
      </Card.Body>
    </Card>
  );
};

export default OrgCard;
