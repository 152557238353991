import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { getCurrencySymbolFromCode } from '../../utils.js';

import FloatingBarChart from './Charts/FloatingBarChart.js';


const TabNavItem = ({loading, noMatches, allFormsVscaError, form, formData, currencyCode, maxLostValue, maxSessionCount, handleSaveFavForm, handleRemoveFavForm, favFormUuids,
  sortedOnValue, animateBars, index}) => {
  return (<>
    {loading &&
      <Nav.Item className="loading-nav-item">
        <Nav.Link eventKey={'loading'} className="px-3 py-2">
          <p><span className="loading-text-placeholder d-inline-flex skeleton-background"></span></p>
          <p><span className="loading-bar-placeholder d-inline-flex skeleton-background"></span></p>
          <p><span className="loading-text-placeholder d-inline-flex skeleton-background"></span></p>
        </Nav.Link>
      </Nav.Item>}
    {noMatches &&
      <Nav.Item className="loading-nav-item">
        <Nav.Link eventKey={'no-matches'} className="px-3 py-2">
        </Nav.Link>
      </Nav.Item>}
    {allFormsVscaError &&
      <Nav.Item className="loading-nav-item">
        <Nav.Link eventKey={'error'} className="px-3 py-2">
        </Nav.Link>
      </Nav.Item>}
    {!allFormsVscaError && !loading && !noMatches &&
      <Nav.Item className={`d-flex ${favFormUuids?.includes(form.uuid) ? 'fav-form' : ''}`}>
        <Nav.Link eventKey={form.uuid} className="px-3 py-2">
          <Row className="tab-item-title-row g-0 justify-content-between">
            <Col className="p-0 col-10">
              <h3>{form.label}</h3>
            </Col>
            <Col className="p-0 col-1">
              {favFormUuids?.includes(form.uuid) ? <AiFillStar size="18px" className="fav-star" title="Remove from favourites"
                data-testid={`fav-form-delete-${form.uuid}`}
                onClick={(e) => {
                  handleRemoveFavForm({form: form});
                }} /> :
                <AiOutlineStar size="18px" className="fav-star-outline" title="Save as a favourite form"
                  data-testid={`fav-form-save-${form.uuid}`}
                  onClick={(e) => {
                    handleSaveFavForm({form: form});
                  }} />}
            </Col>
          </Row>
          <FloatingBarChart
            formData={formData}
            animateBars={animateBars}
            index={index}
            sortedOnValue={sortedOnValue}
            maxLostValue={maxLostValue}
            maxSessionCount={maxSessionCount}
            formUuid={form.uuid}
          />
          <div className="tab-stat-container ms-1">
            <p className="mb-0 tab-stat label">{sortedOnValue === 'lost-first' ? 'Total lost revenue' :
              'Total sessions'}</p>
            <p className="mb-0 tab-stat value">{sortedOnValue === 'lost-first' ? getCurrencySymbolFromCode(currencyCode) + formData?.stats.totalLostValue.toLocaleString() :
              formData?.stats.views.current.toLocaleString()}</p>
          </div>
        </Nav.Link>
      </Nav.Item>}
  </>
  );
};

export default TabNavItem;
