import React from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { VscCheck } from "react-icons/vsc";

const FilterButtons = ({
  filterRecent,
  onSetFilterRecent,
  filterFav,
  onSetFilterFav,
  orgUuid,
}) => {
  return (
    <div className="d-inline-flex align-items-center ms-2 filter-buttons-wrapper">
      <div>
        <ToggleButton
          className="filter-button text-nowrap d-flex align-items-center"
          id={`toggle-recent-${orgUuid}`}
          type="checkbox"
          variant="outline-secondary"
          checked={filterRecent}
          onChange={(e) => onSetFilterRecent(e.currentTarget.checked)}
        >
          {filterRecent && <VscCheck  size="17px" className="me-1" />}
          Recently tracked
        </ToggleButton>
      </div>
      <div>
        <ToggleButton
          className="filter-button text-nowrap d-flex align-items-center"
          id={`toggle-fav-${orgUuid}`}
          type="checkbox"
          variant="outline-secondary"
          checked={filterFav}
          onChange={(e) => onSetFilterFav(e.currentTarget.checked)}
        >
          {filterFav ? <AiFillStar  size="17px" className="me-1" /> : <AiOutlineStar  size="17px" className="me-1" />}
          Favourite forms
        </ToggleButton>
      </div>
    </div>
  );
};

export default FilterButtons;
