import React, { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import moment from 'moment-timezone';
import MixpanelContext from 'react-mixpanel';
import AppContext from '../../AppContext';

import api from '../../api.js';
import DatePicker from '../../Components/DatePicker.tsx';
import ChartsTabPane from './ChartsTabPane.js';
import FilterButtons from './FilterButtons.js';
import TabNavItem from './TabNavItem.js';
import { orgDetailsForMixpanel } from '../../utils.js';

const sortOptions = [
  {label: 'Most revenue lost', value: 'lost-first'},
  {label: 'Total sessions', value: 'total-sessions'},
  {label: 'Recently added', value: 'newest-first'},
  {label: 'A - Z', value: 'a-z'}
];

const OrgTrackedForms = ({org, forms, differentUser}) => {
  const mixpanel = useContext(MixpanelContext);
  const {
    setQuery,
    currentUser,
    setUser,
  } = useContext(AppContext);
  const {uuid: userUuid} = useParams();

  const [sortedForms, setSortedForms] = useState();
  const [sortedOn, setSortedOn] = useState();
  const [sortedFormsSortedOnValue, setSortedFormsSortedOnValue] = useState();
  const [filterRecent, setFilterRecent] = useState();
  const [filterFav, setFilterFav] = useState();
  const [orgTime, setOrgTime] = useState();
  const [animateBars, setAnimateBars] = useState(true);
  const [allFormsVsca, setAllFormsVsca] = useState();
  const [allFormsVscaLoading, setAllFormsVscaLoading] = useState(true);
  const [allFormsVscaError, setAllFormsVscaError] = useState();
  const [allFormsVscaProgress, setAllFormsVscaProgress] = useState();
  const [allFormsVscaWithLost, setAllFormsVscaWithLost] = useState();
  const [allFormsVscaWithLostLoading, setAllFormsVscaWithLostLoading] = useState(true);
  const [maxLostValue, setMaxLostValue] = useState();
  const [allFormsCompletionValue, setAllFormsCompletionValue] = useState();
  const [allFormsCompletionCurrency, setAllFormsCompletionCurrency] = useState();
  const [maxSessionCount, setMaxSessionCount] = useState();
  const [recentlyTrackedFormUuids, setRecentlyTrackedFormUuids] = useState();
  const [activeKey, setActiveKey] = useState();

  const [favForms, setFavForms] = useState();
  const [favFormUuids, setFavFormsUuids] = useState([]);
  const [favFormsError, setFavFormsError] = useState();

  const handleDateTimeRangeChange = useCallback((start, end) => {
    setOrgTime({start, end});
    setQuery((prev) => ({...prev, time: {start, end}}));

    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: 'Dashboard',
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
      ...orgDetailsForMixpanel(org),
    });
  }, [mixpanel, org, setQuery]);

  const handleFormsSort = (opt) => {
    setAnimateBars(true);
    setSortedOn(opt);
    mixpanel.track('Sorted Forms', { page: 'Dashboard', sortedOn: opt.value,  ...orgDetailsForMixpanel(org)});
  };

  const handleSetFilterFav = (bool) => {
    setFilterFav(bool);
    mixpanel.track('Filtered Forms', { page: 'Dashboard', filterOn: 'fav',  ...orgDetailsForMixpanel(org)});
  };

  const handleSetFilterRecent = (bool) => {
    setFilterRecent(bool);
    mixpanel.track('Filtered Forms', { page: 'Dashboard', filterOn: 'recent',  ...orgDetailsForMixpanel(org)});
  };

  const datepickerRanges = useMemo(() => {
    return {
      'Last 7 Days': [moment.tz(org?.timeZone).subtract(7, 'days').startOf('day'), moment.tz(org?.timeZone).subtract(1, 'days').endOf('day')],
      'Last 30 Days': [moment.tz(org?.timeZone).subtract(30, 'days').startOf('day'), moment.tz(org?.timeZone).subtract(1, 'days').endOf('day')],
      'Last 3 Months': [moment.tz(org?.timeZone).subtract(3, 'months').startOf('month').startOf('day'), moment.tz(org?.timeZone).subtract(1, 'month').endOf('month').endOf('day')],
    };
  }, [org?.timeZone]);

  const handleSaveFavForm = useCallback(async ({form}) => {
    try {
      setFavFormsError(null);
      await api.post(`users/${userUuid || currentUser.uuid}/forms/${form.uuid}/favourite`);
      setFavForms(prev => (prev || []).concat(form));
      if (!differentUser) setUser(prev => ({...prev, favouriteForms: (prev.favouriteForms || []).concat(form)}));
      mixpanel.track('Added Favourite Form', { page: 'Dashboard', ...orgDetailsForMixpanel(org)});
    } catch (e) {
      switch (e?.response?.status) {
      case 404:
        setFavFormsError('User or Form not found');
        break;
      default:
        setFavFormsError('Oops, something went wrong saving the favourite form. Please try again.');
      }
    }

  }, [currentUser.uuid, userUuid, setUser, differentUser, mixpanel, org]);

  const handleRemoveFavForm = useCallback(async ({form}) => {
    try {
      setFavFormsError(null);
      await api.delete(`users/${userUuid || currentUser.uuid}/forms/${form.uuid}/favourite`);
      setFavForms(prev => prev.filter(({uuid}) => uuid !== form.uuid));
      if (!differentUser) setUser(prev => ({...prev, favouriteForms: prev.favouriteForms.filter(({uuid}) => uuid !== form.uuid)}));
      mixpanel.track('Removed Favourite Form', { page: 'Dashboard',  ...orgDetailsForMixpanel(org)});
    } catch (e) {
      switch (e?.response?.status) {
      case 404:
        setFavFormsError('User or Form not found');
        break;
      default:
        setFavFormsError('Oops, something went wrong removing the favourite form. Please try again.');
      }
    }
  }, [currentUser.uuid, userUuid, setUser, differentUser, mixpanel, org]);

  useEffect(() => {
    if (userUuid && (userUuid !== currentUser.uuid) && currentUser.accountManager) {
      setFavForms(differentUser?.favouriteForms);
    } else {
      setFavForms(currentUser.favouriteForms);
    }
  }, [userUuid, currentUser, differentUser]);

  useEffect(() => {
    setFavFormsUuids(favForms?.map(({uuid}) => uuid));
  }, [favForms]);

  // Set default time
  useEffect(() => {
    if (org?.timeZone) {
      setOrgTime({
        start: moment.tz(org.timeZone).subtract(30, 'days').startOf('day'),
        end: moment.tz(org.timeZone).subtract(1, 'days').endOf('day'),
      });
    }
  }, [org?.timeZone]);

  // Set default sort
  useEffect(() => {
    if (!sortedOn && org?.createdAt) {
      // Only sort on revenue lost for organisations created after release
      const releaseDate = new Date('2025-04-01T00:00:00Z');
      const orgCreatedDate = new Date(org.createdAt);

      if (orgCreatedDate > releaseDate) {
        setSortedOn(sortOptions.find(o => o.value === 'lost-first'));
      } else {
        setSortedOn(sortOptions.find(o => o.value === 'total-sessions'));
      }
    }
  }, [sortedOn, org?.createdAt]);

  // Extract completion values
  useEffect(() => {
    setAllFormsCompletionValue(forms.reduce((acc, {uuid, completionValue}) => {
      acc[uuid] = completionValue ?? 100; // Allow a 0 value
      return acc;
    }, {}));
  }, [forms]);

  // Extract completion currency
  useEffect(() => {
    setAllFormsCompletionCurrency(forms.reduce((acc, {uuid, completionValueCurrencyCode}) => {
      acc[uuid] = completionValueCurrencyCode || 'usd';
      return acc;
    }, {}));
  }, [forms]);

  // Fetch all forms' VSCA data - we do this all together to be able to sort the highest lost
  useEffect(() => {
    if (orgTime && forms?.length) (async () => {
      try {
        setAllFormsVscaLoading(true);
        setAllFormsVscaWithLostLoading(true);
        setAllFormsVsca(null);
        setAllFormsVscaError(null);
        setAllFormsVscaProgress(10);

        const responses = await Promise.allSettled(forms.filter(f => f.tracked).map((f) => api.get('/visualisations/vsca-chart', {
          params: {
            form: {uuid: f.uuid},
            timePeriod: {start: orgTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: orgTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
            granularity: 'day',
            timeZone: org.timeZone,
          },
        }))
        );

        // TODO: handle any rejections for specific forms
        const successResponses = responses.filter((r) => {
          return r.status === 'fulfilled';
        });

        setAllFormsVsca(successResponses.reduce((acc, response) => {
          const formUuid = response.value.config.params.form.uuid;
          const data = response.value.data;

          // Add non starter dataset
          const startersData = data.chart.datasets.find(d => d.label === 'Starters').data;
          const viewsData = data.chart.datasets.find(d => d.label === 'Views').data;
          data.chart.datasets = data.chart.datasets.concat({
            label: 'non-starter',
            data: viewsData.map((n, i) => n - startersData[i]),
          });
          acc[formUuid] = data;
          return acc;
        }, {}));

      } catch (e) {
        setAllFormsVscaError('Something went wrong loading data for all forms');
        setAllFormsVscaWithLostLoading(false);
      } finally {
        setAllFormsVscaLoading(false);
        setAllFormsVscaProgress(100);
      }
    })();
  }, [orgTime, org?.timeZone, forms, org?.uuid]);

  // Add lost value stat
  useEffect(() => {
    if (allFormsVsca) {
      const allFormsVscaCopy = JSON.parse(JSON.stringify(allFormsVsca));
      for (const [key, data] of Object.entries(allFormsVscaCopy)) {
        const completionValue = allFormsCompletionValue?.[key] || 100;

        // Add lost value stats
        data.stats.totalLostValue = (data.stats.views.current - data.stats.completions.current) * completionValue;
        data.stats.lostNonStartersValue = (data.stats.views.current - data.stats.starters.current) * completionValue;
        data.stats.lostAbandonsValue = (data.stats.starters.current - data.stats.completions.current) * completionValue;
      }
      setAllFormsVscaWithLost(allFormsVscaCopy);
      setAllFormsVscaWithLostLoading(false);
    }
  }, [allFormsVsca, allFormsCompletionValue]);

  // Handle progress bar
  useEffect(() => {
    let interval;
    if (allFormsVscaProgress && allFormsVscaProgress < 100) {
      interval = setInterval(() => setAllFormsVscaProgress((prevProgress) => prevProgress + 30), 100);
    }
    return () => clearInterval(interval);
  }, [allFormsVscaProgress]);

  // Fetch all tracked forms' session data for past 24 hours
  useEffect(() => {
    if (forms?.length && recentlyTrackedFormUuids === undefined) (async () => {
      try {
        const responses = await Promise.allSettled(forms.filter(f => f.tracked).map((f) => api.get('/data/sessions/stats', {
          params: {
            formUuid: f.uuid,
            metric: 'count',
            timePeriod: {start: 'now-24h', end: 'now'},
          },
        }))
        );

        const successResponses = responses.filter((r) => {
          return r.status === 'fulfilled';
        });

        setRecentlyTrackedFormUuids(successResponses.reduce((acc, response) => {
          const formUuid = response.value.config.params.formUuid;
          if (response.value.data.count > 0) acc.push(formUuid);
          return acc;
        }, []));

      } catch (e) {
        // Do nothing - won't be able to filter on recently tracked
      }
    })();
  }, [forms, recentlyTrackedFormUuids]);

  // Find max lost value
  useEffect(() => {
    if (allFormsVscaWithLost) {
      let highestLostValue = 0;
      let highestSessionCount = 0;
      for (const [, data] of Object.entries(allFormsVscaWithLost)) {
        if (data.stats.totalLostValue > highestLostValue) {
          highestLostValue = data.stats.totalLostValue;
        }
        if (data.stats.views.current > highestSessionCount) {
          highestSessionCount = data.stats.views.current;
        }
      }
      setMaxLostValue(highestLostValue);
      setMaxSessionCount(highestSessionCount);
    }
  }, [allFormsVscaWithLost]);

  // Org form cards filter and sorting
  useEffect(() => {
    if (allFormsVscaWithLost && sortedOn?.value && recentlyTrackedFormUuids) {
      const sortForms = ({allForms, sortedOn}) => {
        switch (sortedOn) {
        case 'lost-first':
          return allForms.sort(({uuid: uuidA}, {uuid: uuidB}) => {
            if (!allFormsVscaWithLost[uuidA]) return -1;
            if (!allFormsVscaWithLost[uuidB]) return -1;
            const lostOppA = allFormsVscaWithLost[uuidA].stats.totalLostValue;
            const lostOppB = allFormsVscaWithLost[uuidB].stats.totalLostValue;
            return lostOppB - lostOppA;
          });
        case 'total-sessions':
          return allForms.sort(({uuid: uuidA}, {uuid: uuidB}) => {
            if (!allFormsVscaWithLost[uuidA]) return -1;
            if (!allFormsVscaWithLost[uuidB]) return -1;
            const viewsA = allFormsVscaWithLost[uuidA].stats.views.current;
            const viewsB = allFormsVscaWithLost[uuidB].stats.views.current;
            return viewsB - viewsA;
          });
        case 'newest-first':
          return allForms.sort((a,b) => a.createdAt.localeCompare(b.createdAt));
        case 'a-z':
          return allForms.sort((a,b) => a.label.localeCompare(b.label));
        default:
          return allForms;
        }
      };

      const filteredForms = forms.filter(f => {
        let keep = true;
        if (filterFav) keep = favFormUuids?.includes(f.uuid);
        if (keep && filterRecent) keep = recentlyTrackedFormUuids?.includes(f.uuid);
        return keep;
      });
      const sorted = sortForms({allForms: filteredForms, sortedOn: sortedOn?.value});
      setSortedFormsSortedOnValue(sortedOn?.value);
      setSortedForms(sorted);
    }
  }, [allFormsVscaWithLost, sortedOn?.value, forms, recentlyTrackedFormUuids, favFormUuids, filterFav, filterRecent]);

  // Select first tab
  useEffect(() => {
    if (sortedForms?.length && !activeKey) {
      setActiveKey(sortedForms[0].uuid);
    }
  }, [sortedForms, activeKey]);

  // Select new tab if selected is not in sorted list
  useEffect(() => {
    if (sortedForms?.length && activeKey && sortedForms.findIndex(f => f.uuid === activeKey) < 0) {
      setActiveKey(sortedForms[0].uuid);
    }
  }, [sortedForms, activeKey]);

  const handleUpdatingFormCompletion = useCallback(({uuid, completionValue, completionValueCurrencyCode}) => {
    setAnimateBars(false);
    setAllFormsCompletionValue({...allFormsCompletionValue, [uuid]: completionValue});
    setAllFormsCompletionCurrency({...allFormsCompletionCurrency, [uuid]: completionValueCurrencyCode});
  }, [allFormsCompletionValue, allFormsCompletionCurrency]);

  useEffect(() => {
    if (!sortedForms?.length && (allFormsVscaLoading || allFormsVscaWithLostLoading)) setActiveKey('loading');
    if (sortedForms?.length === 0 && (!allFormsVscaLoading && !allFormsVscaWithLostLoading)) setActiveKey('no-matches');
  }, [sortedForms, allFormsVscaLoading, allFormsVscaWithLostLoading]);

  useEffect(() => {
    if (allFormsVscaError) setActiveKey('error');
  }, [allFormsVscaError]);

  return (<div className="tracked-forms">
    <p className="mb-2">See high-level metrics across your tracked forms, and use the direct links to explore more data across the Form, Field and Segment reports.</p>
    <Row className="g-0">
      <Col className="p-0 mb-1 me-3 d-flex align-items-center col-auto" id="datepicker">
        <DatePicker
          startTime={orgTime?.start}
          endTime={orgTime?.end}
          onApply={handleDateTimeRangeChange}
          timeZone={org?.timeZone}
          selectedFormTimeZone={org?.timeZone}
          timePicker={false}
          autoApply={true}
          alwaysShowCalendars={false}
          showRangeLabels={true}
          ranges={datepickerRanges}
        />
      </Col>
      <Col className="p-0 mb-1 me-3 d-flex col-auto">
        <div className="d-inline-flex align-items-center">
          <Form.Label className="me-2 mb-0 text-nowrap" htmlFor={`sort-select-${org.uuid}`}>Sort by:</Form.Label>
          <Select
            id={`sort-select-${org.uuid}`}
            htmlId={`sort-select-${org.uuid}`}
            styles={{
              control: (styles, state) => ({...styles,
                border: '1px solid #EBEDF2',
              }),
              option: (styles, state) => ({...styles,
                color: '#3f4047',
                backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5Ec" : null,
                '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
              }),
              menu: (styles, state) => ({...styles,
                marginTop: '1px',
                borderRadius: '4px',
                border: '1px solid #EBEDF2',
                boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
              }),
              singleValue: (styles, state) => ({...styles,
                color: '#3f4047',
              }),
              dropdownIndicator: (styles, state) => ({...styles,
                cursor: 'pointer',
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                transition: 'transform .5s ease',
              }),
            }}
            options={sortOptions}
            onChange={handleFormsSort}
            placeholder="Select..."
            value={sortedOn}
            aria-label={'Sorted On'}
          />
        </div>
      </Col>
      <Col className="p-0 mb-1 d-flex">
        <div className="d-inline-flex align-items-center">
          <p className="mb-0 text-nowrap">Filter on:</p>
          <FilterButtons
            filterRecent={filterRecent}
            onSetFilterRecent={handleSetFilterRecent}
            filterFav={filterFav}
            onSetFilterFav={handleSetFilterFav}
            orgUuid={org?.uuid}
          />
        </div>
      </Col>
    </Row>
    {favFormsError && <p className="error-text">{favFormsError}</p>}
    <p className="mb-0">Select form:</p>
    <TabContainer id="forms-dashboard"
      activeKey={activeKey}
      onSelect={(formUuid) => {
        setActiveKey(formUuid);
        setAnimateBars(false);
        mixpanel.track('Selected Form', { page: 'Dashboard',  ...orgDetailsForMixpanel(org)});
      }}>
      <div className="scrollable-grid">
        <div className="d-grid tabs-grid">
          <div className={`grid-scrollable-column ${(!sortedForms?.length) && (allFormsVscaLoading || allFormsVscaWithLostLoading) ? 'loading-grid' : ''}`}>
            <div className="grid-background">
              <Nav className="flex-column">
                {allFormsVscaError &&
                <TabNavItem
                  allFormsVscaError={allFormsVscaError}
                />
                }
                {(!sortedForms?.length) && (allFormsVscaLoading || allFormsVscaWithLostLoading) &&
                <TabNavItem
                  loading={true}
                />
                }
                {(sortedForms?.length === 0) && (!allFormsVscaLoading && !allFormsVscaWithLostLoading) &&
                <TabNavItem
                  noMatches={true}
                />
                }
                {(!allFormsVscaError && sortedForms?.length > 0 && allFormsVscaWithLost) ? sortedForms.map((f,index) =>
                  <TabNavItem
                    key={`nav-item-${f.uuid}`}
                    index={index}
                    formData={allFormsVscaWithLost[f.uuid]}
                    form={f}
                    currencyCode={allFormsCompletionCurrency[f.uuid]}
                    maxLostValue={maxLostValue}
                    maxSessionCount={maxSessionCount}
                    handleSaveFavForm={handleSaveFavForm}
                    handleRemoveFavForm={handleRemoveFavForm}
                    favFormUuids={favFormUuids}
                    sortedOnValue={sortedFormsSortedOnValue}
                    animateBars={animateBars}
                    loading={allFormsVscaLoading || allFormsVscaWithLostLoading}
                  />
                ) : null
                }
              </Nav>
            </div>
          </div>
          <div className="grid-tab-content-area">
            <TabContent className="p-2">
              <ChartsTabPane
                loading={true}
              />
              <ChartsTabPane
                noMatches={true}
              />
              <ChartsTabPane
                allFormsVscaError={allFormsVscaError}
              />
              {(sortedForms?.length > 0 && allFormsVscaWithLost) ? sortedForms.map(f =>
                <ChartsTabPane
                  key={`tab-pane-${f.uuid}`}
                  form={f}
                  sortedOn={sortedOn}
                  isActive={activeKey === f.uuid}
                  formData={allFormsVscaWithLost[f.uuid]}
                  orgTime={orgTime}
                  org={org}
                  handleUpdatingFormCompletion={handleUpdatingFormCompletion}
                  currencyCode={allFormsCompletionCurrency[f.uuid]}
                  completionValue={allFormsCompletionValue[f.uuid]}
                  formDataLoading={allFormsVscaWithLostLoading}
                  allFormsVscaProgress={allFormsVscaProgress}
                  datepickerRanges={datepickerRanges}
                />
              )
                : null
              }
            </TabContent>
          </div>
        </div>
      </div>
    </TabContainer>
  </div>
  );
};

export default OrgTrackedForms;
