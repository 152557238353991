import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import moment from 'moment-timezone';
import api from '../../api.js';

import { getCurrencySymbolFromCode } from '../../utils.js';

const LiveData = ({
  formUuid,
  viewToCompletionRate,
  completionValue,
  currencyCode,
  vscRatesLoading,
}) => {
  const [views, setViews] = useState();
  const [completions, setCompletions] = useState();
  const [estimateAbandonLoss, setEstimatedAbandonLoss] = useState();
  const [liveDataLoading, setLiveDataLoading] = useState();
  const [liveDataError, setLiveDataError] = useState();
  const [viewToAbandonRate, setViewToAbandonRate] = useState();

  useEffect(() => {
    (async () => {
      try {
        setLiveDataError(null);
        setLiveDataLoading(true);
        const { data } = await api.get('/events', {
          params: {
            eventTypes: ['formView', 'completion'],
            form: { uuid: formUuid },
            timePeriod: {
              start: moment().subtract(15, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              end: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
            },
            granularity: 'hour',
            timeZone: 'UTC', // Default as we only need the total
          },
        });

        const isData = (data && data.labels.length > 0);
        if (!isData) {
          setLiveDataError('No data to display');
        } else {
          setCompletions(data.datasets.find(d => d.label === 'Completions').data[0]);
          setViews(data.datasets.find(d => d.label === 'Views').data[0]);
        }
      } catch (e) {
        setLiveDataError((e.response && (e.response.status === 404)) ? 'Form not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      } finally {
        setLiveDataLoading(false);
      }
    })();
  }, [formUuid]);

  useEffect(() => {
    if (viewToCompletionRate) {
      setViewToAbandonRate(((100 - viewToCompletionRate)));
    }
  }, [viewToCompletionRate]);

  useEffect(() => {
    if (views && viewToAbandonRate) {
      setEstimatedAbandonLoss((views * (viewToAbandonRate/100)) * completionValue);
    }
  }, [views, viewToAbandonRate, completionValue]);

  return (
    <div className="live-sessions p-2 h-100">
      <div className="mini-card h-100 d-flex flex-column">
        <Row className="g-0 justify-content-between">
          <Col className="p-0 col-auto">
            <h4 className="text-start fw-normal">Recent activity</h4>
          </Col>
          <Col className="ms-1 p-0 d-flex justify-content-end">
            <p className="mb-0 me-1 info-message"><small>Live data over the past 15 minutes</small></p>
          </Col>
        </Row>
        {liveDataError && <p>{liveDataError}</p>}
        {!liveDataLoading && !vscRatesLoading &&
          <Row className="g-0 justify-content-betweenx text-center flex-grow-1">
            <Col className="py-0 px-2 d-flex flex-column col-autox">
              <p className="mb-0">Views</p>
              <p className="mb-0 fs-5 flex-grow-1 align-content-center">{views?.toLocaleString()}</p>
            </Col>
            <Col className="py-0 px-2 d-flex flex-column col-autox">
              <p className="mb-0">Completions</p>
              <p className="mb-0 fs-5 flex-grow-1 align-content-center">{completions?.toLocaleString()}</p>
            </Col>
            {estimateAbandonLoss && <Col className="py-1 px-2 col-auto d-flex justify-content-center mx-auto">
              <div className="estimated-abandon-box h-100 d-flex flex-column justify-content-center">
                <p className="mb-0"><OverlayTrigger placement="top" overlay={<Popover  className="dashboard-popover">
                  <Popover.Body className="fs-6 text-start">
                    <p className="mb-1">Estimated lost revenue is calculated using the <i>View to Abandon rate</i>. This is the % of visitors who visited the form, but did not complete it.</p>
                    <p className="mb-0 text-center">View to Abandon rate: <span className="fs-5">{viewToAbandonRate.toLocaleString(undefined, {maximumFractionDigits: 0})}%</span></p>
                  </Popover.Body>
                </Popover>}>
                  <u className="text-more-info">Estimated</u>
                </OverlayTrigger> lost revenue</p>
                <p className="mb-0 fs-6 fw-500 flex-grow-1 align-content-center">{getCurrencySymbolFromCode(currencyCode)}{estimateAbandonLoss.toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
              </div>
            </Col>}
          </Row>}
      </div>
    </div>
  );
};

export default LiveData;
