import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import AppContext from './AppContext';
import {MixpanelConsumer} from 'react-mixpanel';
import FreshChat from 'react-freshchat';
import './App.scss';
import './Print.scss';
import ProfileEdit from './Page/ProfileEdit';
import SessionExplorer from './Page/SessionExplorer';
import ChoosePlan from './Page/ChoosePlan';
import FieldData from './Page/FieldData';
import FormData from './Page/FormData';
import FormAdd from './Page/FormAdd';
import FormEdit from './Page/FormEdit';
import FormFieldsConfig from './Page/FormFieldsConfig';
import FormTrackingCode from './Page/FormTrackingCode';
import FormIntegrations from './Page/FormIntegrations';
import FormSegmentComparison from './Page/SegmentComparison';
import SessionReplay from './Page/SessionReplay';
import FieldSegmentComparison from './Page/SegmentComparisonField';
import Alerts from './Page/Alerts';
import AlertConfig from './Page/AlertConfig';
import AlertConfirmSubscription from './Page/AlertConfirmSubscription';
import Live from './Page/Live';
import Dashboard from './Page/Dashboard';
import Login from './Page/Login';
import Usage from './Page/Usage';
import Accounts from './Page/Accounts';
import OrgCreate from './Page/OrgCreate';
import OrgAgencyCreate from './Page/OrgCreate/OrgAgencyCreate';
import SignUp from './Page/SignUp';
import BuilderSignUp from './Page/Builder/SignUp';
import ShopifySignUp from './Page/Integrations/Shopify/SignUp';
import IntegrationsError from './Page/Integrations/Error';
import Integrations from './Page/Integrations';
import FieldFlow from './Page/FieldFlow';
import FunnelBuilder from './Page/FunnelBuilder';
import Insights from './Page/Insights';
import NotFound from './Page/NotFound';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './ProtectedRoute';
import RequestPasswordReset from './Page/RequestPasswordReset';
import SetPassword from './Page/SetPassword';
import InvitedAccountSetPassword from './Page/InvitedAccountSetPassword';
import OrgEdit from './Page/OrgEdit';
import OrgAgencyClientCreate from './Page/OrgCreate/OrgAgencyClientCreate';
import Builder from './Builder';
import WeeklySummaryEmailUnsubscribe from './Page/WeeklySummaryEmailUnsubscribe';
import { useAppAlerts, useOrgAppAlerts, useBuilderOrgAppAlert, usePrevious } from './hooks';
import { getSession, updateSession } from './utils';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { VscWarning } from 'react-icons/vsc';
import Forms from './forms';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';

export const acceptedGranularity = ['hour', 'day', 'week', 'month'];

const App = () => {
  const [currentUser, setUser] = useState(null);
  // TODO: Change to have just one time zone at the app level
  const [timeZone, updateTimeZone] = useState();
  const [currentOrg, setCurrentOrg] = useState(null); // TODO: Rename to org
  const [appAlerts, setAppAlerts] = useAppAlerts();
  const [orgAppAlerts, setOrgAppAlerts] = useOrgAppAlerts(currentOrg);
  const [builderOrgAppAlert, setBuilderOrgAppAlert] = useBuilderOrgAppAlert(currentUser?.organisations?.[0]);
  const [query, setQuery] = useState();
  const [formsLoading, setFormsLoading] = useState(false);
  const [formsLoadingError, setFormsLoadingError] = useState(null);
  const [formsGroupedByOrg, setFormsGroupedByOrg] = useState();
  const [formsGroupedByFormUuid, setFormsGroupedByFormUuid] = useState();
  const [showFieldsConfigInfo, setShowFieldsConfigInfo] = useState(true);
  const [currentUserRoleInOrgs, setCurrentUserRoleInOrgs] = useState({});
  const [hasShownReviewOpen, setHasShownReviewOpen] = useState(false);

  const prevQuery = usePrevious(query);

  const resetSession = () => {
    setCurrentOrg(null);
    updateTimeZone(null);
    setAppAlerts(null);
    setOrgAppAlerts(null);
    setQuery(null);
    Forms.clear();
  };

  // Follow form changes to update org
  useEffect(() => {
    if (
      (prevQuery && !prevQuery.form && query?.form?.organisation) || // When default form is selected
      ((prevQuery && prevQuery.form && !prevQuery.form.organisation) && query?.form?.organisation) || // When the form from params/session is selected
         (query?.form?.organisation?.uuid && prevQuery?.form?.organisation?.uuid && (prevQuery.form.organisation.uuid !== query.form.organisation.uuid))) { // When the form changes
      setCurrentOrg(query.form.organisation);
    }
  }, [prevQuery, query]);

  // Follow organisation's timezone changes
  useEffect(() => {
    if (currentOrg?.timeZone) {
      updateTimeZone(currentOrg.timeZone);
    }
  }, [currentOrg?.timeZone]);

  // No timezone on the Org, so set default
  useEffect(() => {
    if (currentOrg && !currentOrg.timeZone) {
      updateTimeZone('UTC');
    }
  }, [currentOrg]);

  // Update the session any time the query changes e.g. first load, clicking Apply, or browser navigation
  useEffect(() => {
    const { form, time, granularity, filters, sessionOutcomes, fieldFlow, sessionExplorer, fieldSegmentComparison,
      submitFieldIdentifier, sessionFilters,
    } = query || {};
    if ((form || (time?.start && time?.end) || granularity || filters || sessionOutcomes ||
    fieldFlow || sessionExplorer || sessionFilters || fieldSegmentComparison || submitFieldIdentifier)) {
      const session = getSession();
      if (form) session.form = form;
      if (time?.start && time?.end) {
        session.timeframe = {
          start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        };
      }
      if (granularity) session.granularity = granularity;
      if (filters) session.filters = filters.reduce((acc, {key, label: value}) => {
        if (!acc.hasOwnProperty(key)) acc[key] = [];
        acc[key].push(value);
        return acc;
      }, {});
      if (sessionOutcomes) session.sessionOutcomes = sessionOutcomes.map(({value}) => value);
      if (fieldFlow) session.fieldFlow = fieldFlow;
      if (sessionExplorer) session.sessionExplorer = sessionExplorer;
      if (sessionFilters) session.sessionFilters = sessionFilters;
      if (fieldSegmentComparison) session.fieldSegmentComparison = fieldSegmentComparison;
      if (submitFieldIdentifier !== undefined) session.submitField = submitFieldIdentifier;
      updateSession(session);
    }
  }, [query]);

  // Unset fields saved in the session when the query's form changes
  useEffect(() => {
    if (query?.form?.uuid) {
      const session = getSession();
      if (session.fields) {
        delete session.fields;
        updateSession(session);
      }
    }
  }, [query?.form?.uuid]);

  // Unset fields saved in the query when the query's form changes on a page which doesn't use either of these fields
  useEffect(() => {
    if ((query?.form?.uuid && prevQuery?.form?.uuid && (prevQuery.form.uuid !== query.form.uuid))) {
      setQuery(prev => ({...prev,
        fieldFlow: {...prev?.fieldFlow, flowFieldIdentifier: null},
        submitFieldIdentifier: null,
      }));
    }
  }, [prevQuery, query]);

  useEffect(() => {
    if (!currentUser) {
      try {
        setUser(JSON.parse(localStorage.getItem('zukoAppUser')));
      } catch (e) {/* Do nothing - we *assume* there is no user in localStorage */}
    }
    if (currentUser) localStorage.setItem('zukoAppUser', JSON.stringify(currentUser));

    if (currentUser?.roles) setCurrentUserRoleInOrgs(currentUser.roles.reduce((acc, role) => {
      if (!role.resourceUuid) return acc;

      // We only support one role per user per org but want to maintain 'admin' if that exists
      if (!acc[role.resourceUuid]) acc[role.resourceUuid] = {};
      if (acc[role.resourceUuid] !== 'admin') acc[role.resourceUuid] = role;
      return acc;
    }, {}));
  }, [currentUser]);

  // Start recording session replay for all sessions for normal users
  useEffect(() => {
    if (currentUser?.organisations && !currentUser.accountManager &&
      !currentUser.email.includes('gmail')
    ) {
      const firstOrgSignUpProduct = currentUser.organisations?.[0]?.signUpProduct;
      if (firstOrgSignUpProduct === 'builder') return;
      const sentryClient = Sentry.getClient();
      const existingReplay = sentryClient?.getIntegrationById('Replay');
      if (sentryClient && !existingReplay) {
        Sentry.setUser({ email: currentUser.email});
        Sentry.addIntegration(Sentry.replayIntegration({
          maskAllText: false,
          unmask: ['#form-details-form #url'],
        }));
        // By setting this tag, we can search Replays e.g. tags[sign_up_product_first_org]:builder
        Sentry.setTag('sign_up_product_first_org', firstOrgSignUpProduct === 'builder' ? 'builder' : 'analytics');
      }
    }
  }, [currentUser]);

  return ( <>
    <AppContext.Provider value={{
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '/api',
      currentUser, setUser,
      timeZone, updateTimeZone,
      currentOrg, setCurrentOrg,
      appAlerts, setAppAlerts,
      orgAppAlerts, setOrgAppAlerts,
      builderOrgAppAlert, setBuilderOrgAppAlert,
      query, setQuery,
      formsLoading, setFormsLoading,
      formsLoadingError, setFormsLoadingError,
      formsGroupedByOrg, setFormsGroupedByOrg,
      formsGroupedByFormUuid, setFormsGroupedByFormUuid,
      resetSession,
      showFieldsConfigInfo, setShowFieldsConfigInfo,
      currentUserRoleInOrgs,
      hasShownReviewOpen, setHasShownReviewOpen,
    }}>
      {currentUser && currentUser.uuid && currentUser.email &&
          <FreshChat
            token={process.env.REACT_APP_FRESHCHAT_TOKEN || 'NOT_SET'}
            onInit={widget => {
              widget.setExternalId(currentUser.uuid);
              widget.user.setEmail(currentUser.email);
            }}
          />}
      <MixpanelConsumer>
        {mixpanel =>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
            <Router>
              {process.env.REACT_APP_CI_MERGE_REQUEST_ID &&
            (process.env.REACT_APP_PRODUCTION_API_BASE_URL === process.env.REACT_APP_API_BASE_URL) &&
              <Row className="alert-row g-0 justify-content-center w-100" style={{position: 'fixed', top: 0, zIndex: 15}}>
                <Alert variant="warning" style={{marginBottom: 0, backgroundColor: '#ffe46fe6', padding: '2px 10px', width: 'fit-content'}}>
                  <div className="page-alert-svg-icon"><VscWarning size="100%"/></div>
                  <p className="alert-text m-0">You are viewing production data. Any changes you make in this review app will be visible in production.</p>
                </Alert>
              </Row>}
              <Switch>
                <Route path="/signup">
                  <SignUp mixpanel={mixpanel}/>
                </Route>
                <Route path="/login">
                  <Login mixpanel={mixpanel}/>
                </Route>
                <Route exact path="/password-reset">
                  <RequestPasswordReset mixpanel={mixpanel}/>
                </Route>
                <Route path="/password-reset/:token">
                  <SetPassword mixpanel={mixpanel}/>
                </Route>
                <Route path="/create-account/:token">
                  <InvitedAccountSetPassword mixpanel={mixpanel}/>
                </Route>
                <PrivateRoute component={FormData} path="/form_aggregate" mixpanel={mixpanel}/>
                <PrivateRoute component={Dashboard} path="/dashboard/:uuid" mixpanel={mixpanel}/>
                <PrivateRoute component={Dashboard} path="/dashboard" mixpanel={mixpanel}/>
                <PrivateRoute component={ProfileEdit} path="/profile" mixpanel={mixpanel}/>
                {/* TODO: Change URL to /session-explorer as it's a bit more 'web-friendly' to have hyphens in URLs. */}
                <PrivateRoute component={SessionExplorer} path="/session_explorer" mixpanel={mixpanel}/>
                <PrivateRoute component={ChoosePlan} path="/organisations/:uuid/choose-plan" mixpanel={mixpanel}/>
                <PrivateRoute component={FieldData} path="/field_aggregate" mixpanel={mixpanel}/>
                <PrivateRoute component={FieldFlow} path="/field-flow" mixpanel={mixpanel}/>
                <PrivateRoute component={FunnelBuilder} path="/funnel" mixpanel={mixpanel}/>
                <PrivateRoute component={Insights} path="/insights" mixpanel={mixpanel}/>
                <PrivateRoute component={SessionReplay} path="/session-replay" mixpanel={mixpanel}/>
                <PrivateRoute component={FormAdd} path="/forms/new" mixpanel={mixpanel}/>
                <PrivateRoute component={FormEdit} path="/forms/:uuid/edit" mixpanel={mixpanel}/>
                <PrivateRoute component={FormFieldsConfig} path="/forms/:uuid/fields" mixpanel={mixpanel}/>
                <PrivateRoute component={FormTrackingCode} path="/forms/:uuid/tracking_code" mixpanel={mixpanel}/>
                <PrivateRoute component={FormIntegrations} path="/forms/:uuid/integrations" mixpanel={mixpanel}/>
                <Route path="/segment_comparison">
                  <Redirect to="/form-segment-comparison" />
                </Route>
                <PrivateRoute component={FormSegmentComparison} path="/form-segment-comparison" mixpanel={mixpanel}/>
                <PrivateRoute component={FieldSegmentComparison} path="/field-segment-comparison" mixpanel={mixpanel}/>
                <PrivateRoute component={Alerts} path="/organisations/:uuid/alerts" mixpanel={mixpanel}/>
                <PrivateRoute component={AlertConfig} path="/alerts/new" configType={'add'} mixpanel={mixpanel}/>
                <PrivateRoute component={AlertConfig} path="/alerts/:uuid/edit" configType={'edit'} mixpanel={mixpanel}/>
                <PrivateRoute component={Live} path="/live" mixpanel={mixpanel}/>
                <PrivateRoute component={Usage} path="/users/:uuid/organisations_usage" mixpanel={mixpanel}/>
                <PrivateRoute component={OrgEdit} path="/organisations/:uuid/edit" mixpanel={mixpanel}/>
                <PrivateRoute component={OrgAgencyClientCreate} path="/organisations/:uuid/agency_clients/new" mixpanel={mixpanel}/>
                <ProtectedRoute component={Accounts} path="/accounts" mixpanel={mixpanel}/>
                <ProtectedRoute component={OrgCreate} path="/organisations/new" mixpanel={mixpanel}/>
                <ProtectedRoute component={OrgAgencyCreate} path="/agencies/new" mixpanel={mixpanel}/>
                <Route path="/alerts/:uuid/confirm_subscription">
                  <AlertConfirmSubscription/>
                </Route>
                <Route path="/users/:uuid/weekly_summary_unsubscribe">
                  <WeeklySummaryEmailUnsubscribe/>
                </Route>


                <Route path="/builder/signup">
                  <BuilderSignUp mixpanel={mixpanel} product={'builder'}/>
                </Route>
                <PrivateRoute component={Builder} path="/builder" mixpanel={mixpanel}/>

                <Route path="/integrations/install_error">
                  <IntegrationsError mixpanel={mixpanel}/>
                </Route>
                <Route path="/integrations/shopify/signup">
                  <ShopifySignUp mixpanel={mixpanel} product={'analytics'}/>
                </Route>
                <Route path="/integrations/shopify/login">
                  <Login mixpanel={mixpanel}/>
                </Route>
                <Route path="/integrations">
                  <Integrations mixpanel={mixpanel}/>
                </Route>

                <PrivateRoute component={Dashboard} exact path="/" mixpanel={mixpanel}/>
                <Route component={NotFound} path="*"/>
              </Switch>
            </Router>
          </GoogleOAuthProvider>
        }
      </MixpanelConsumer>
    </AppContext.Provider>
  </>);
};

export default App;
