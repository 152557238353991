import React, { useEffect, useContext } from 'react';
import TabPane from 'react-bootstrap/TabPane';
import ChartsPanel from './ChartsPanel.js';
import AppContext from '../../AppContext';

const ChartsTabPane = ({loading, noMatches, form, formData, orgTime, org, isActive, sortedOn,
  handleUpdatingFormCompletion, currencyCode, completionValue,
  formDataLoading, allFormsVscaProgress, allFormsVscaError,
  datepickerRanges,
}) => {
  const {
    setQuery,
  } = useContext(AppContext);

  useEffect(() => {
    if (isActive) setQuery((prev) => ({...prev, form: {uuid: form?.uuid}}));
  }, [isActive, form?.uuid, setQuery]);

  return (<>
    {loading &&
      <TabPane eventKey={'loading'}>
        <p><span className="loading-text-placeholder d-inline-flex skeleton-background"></span></p>
      </TabPane>}
    {noMatches &&
      <TabPane eventKey={'no-matches'}>
        <p>No forms match the filters</p>
      </TabPane>}
    {allFormsVscaError &&
      <TabPane eventKey={'error'}>
        <p>{allFormsVscaError}</p>
      </TabPane>}
    {!allFormsVscaError && !loading && !noMatches && form?.uuid &&
      <TabPane eventKey={form.uuid}>
        <ChartsPanel
          formData={formData}
          isActive={isActive}
          orgTime={orgTime}
          org={org}
          form={form}
          sortedOn={sortedOn}
          handleUpdatingFormCompletion={handleUpdatingFormCompletion}
          currencyCode={currencyCode}
          completionValue={completionValue}
          formDataLoading={formDataLoading}
          allFormsVscaProgress={allFormsVscaProgress}
          datepickerRanges={datepickerRanges}
        />
      </TabPane>}
  </>
  );
};

export default ChartsTabPane;
