import React, { useState, useMemo } from 'react';
import { ArcElement, DoughnutController, Legend } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { getCurrencySymbolFromCode } from '../../../utils.js';
import ProgressBar from 'react-bootstrap/ProgressBar';

import ChartDataLabels from 'chartjs-plugin-datalabels';

ReactChart.register(DoughnutController, ArcElement, Legend);

const SplitDoughnutChart = ({formData, currencyCode,
  formDataLoading, formDataError, allFormsVscaProgress,
}) => {
  const [displayLabels, setDisplayLabels] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const data = useMemo(() => {
    const notStartedSessions = (formData?.stats.views.current - formData?.stats.starters.current);
    const abandonedSessions = (formData?.stats.starters.current - formData?.stats.completions.current);
    const completedSessions =  formData?.stats.completions.current;
    const notStartedLost = formData?.stats.lostNonStartersValue;
    const abandonedLost = formData?.stats.lostAbandonsValue;

    const chartData = [notStartedSessions, abandonedSessions, completedSessions];
    const sum = chartData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    },0);

    const fixedColours = ['#05B2DA', '#F0764A', '#006ec2'];
    const fixedMutedColours = ['#e3f3f7', '#F4DBD8', '#c1e3fd'];

    return {
      labels: [
        'Not started',
        'Abandoned',
        'Completed'
      ],
      datasets: [{
        backgroundColor: (ctx) => fixedColours[ctx.dataIndex],
        hoverBorderColor: 'white',
        data: chartData,
        datalabels: {
          labels: {
            outsideValue: {
              display: (ctx) => {
                return ctx.active
                  ? displayLabels
                  : displayLabels && 'auto';
              },
              align: 'end',
              anchor: 'end',
              clamp: true,
              color: (ctx) => {
                return (ctx.active && ctx.dataIndex < 2)
                  ?  '#575962'
                  : fixedColours[ctx.dataIndex];
              },
              textAlign: 'center',
              font: (ctx) => {
                return {
                  weight: '400',
                  size: (ctx.active && ctx.dataIndex < 2)
                    ? '14px'
                    : '16px',
                  lineHeight: '1.1',
                };
              },
              padding: {
                left: 4,
                right: 4,
                top: 3,
                bottom: 2,
              },
              backgroundColor: (ctx) => {
                return (ctx.active && ctx.dataIndex < 2)
                  ? '#f5f5f5'
                  : fixedMutedColours[ctx.dataIndex];
              },
              borderRadius: 2,
              formatter: (value, ctx) => {
                if (!value) return null;
                const labelsLost = [['Lost revenue', `${getCurrencySymbolFromCode(currencyCode)}${notStartedLost.toLocaleString()}`, ],
                  ['Lost revenue', `${getCurrencySymbolFromCode(currencyCode)}${abandonedLost.toLocaleString()}`]];

                const labels = ['Not started', 'Abandons', 'Completions'];

                const percent = value / sum * 100;
                return (ctx.active && ctx.dataIndex < 2)
                  ? labelsLost[ctx.dataIndex]
                  : ((percent < 1) ? null : labels[ctx.dataIndex]);
              },
              offset: 12,
            },
            insideValue: {
              display: displayLabels,
              font: {
                weight: '500',
                size: '15px',
              },
              textAlign: 'center',
              color: (ctx) => {
                return ctx.active ? fixedColours[ctx.dataIndex] : 'white';
              },
              backgroundColor: (ctx) => {
                return ctx.active ? fixedMutedColours[ctx.dataIndex] : null;
              },
              borderRadius: 2,
              formatter: (value, ctx) => {
                const percent = value / sum * 100;
                const labelsSessions = [[`${notStartedSessions.toLocaleString()}`, 'sessions'], [`${abandonedSessions.toLocaleString()}`, 'sessions'],  [`${completedSessions.toLocaleString()}`, 'sessions']];
                return ctx.active ? labelsSessions[ctx.dataIndex] : (percent < 6) ? null : percent.toFixed(0) + '%';
              },
            },
          },
        }
      }]
    };
  }, [displayLabels, currencyCode, formData]);

  const options = useMemo(() => ({
    onHover: (event, chartElement) => {
      // Enable cursor pointer when hovering over a chart element
      return event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    animation: loaded ? false : {
      onProgress: () => {
        if (displayLabels) setDisplayLabels(false);
      },
      onComplete: () => {
        setDisplayLabels(true);
        setLoaded(true);
      },
      animateRotate: true,
      easing: 'easeOutCirc',
      colors: {type: "color",duration: 1000,from: "transparent",}
    },
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false
      },
      legend: {
        display: false,
        onClick: () => null,
        labels: {
          font: {
            size: '14px',
          },
          boxWidth: 20,
        },
      },
      title: {
        display: false,
      },
    },
    aspectRatio: 3 / 2,
    cutout: 35,
    layout: {
      padding: {
        top: 45,
        bottom: 45,
        left: 90,
        right: 90,
      }
    },
  }), [displayLabels, loaded]);

  const plugins = useMemo(() => ([
    ChartDataLabels, // Directly add datalabels to this chart
  ]), []);

  return (
    <>
      {formDataLoading &&
      <div className="progress-area d-flex flex-grow-1 p-2 align-items-center">
        <div className="w-100">
          <ProgressBar className="my-auto" animated now={allFormsVscaProgress}/>
        </div>
      </div>}
      {formDataError && <div className="d-flex justify-content-center align-items-center error-msg-area"><p>{formDataError}</p></div>}
      {!formDataLoading && !formDataError &&
        <div className="session-outcomes-doughnut-chart mx-auto d-flex justify-content-center">
          <ReactChart
            id="split-doughnut-chart"
            type="doughnut"
            data={data}
            width={375}
            options={options}
            plugins={plugins}
          /></div>}
    </>
  );
};

export default SplitDoughnutChart;
