import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { VscChromeClose } from "react-icons/vsc";
import { FaInfoCircle, FaSpinner } from "react-icons/fa";
import { GrDocumentPdf, GrDocumentCsv } from "react-icons/gr";
import { AiOutlineLineChart } from "react-icons/ai";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Modal from 'react-bootstrap/Modal';
import Select, { components }  from 'react-select';
import {
  BarController, BarElement, CategoryScale, // For bar charts
  Tooltip, Legend // Additional utils
} from 'chart.js';
import { ReactChart } from 'chartjs-react';
import { Chart } from 'chart.js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import arrayMove from 'array-move';
import numeral from 'numeral';
import qs from 'qs';
import { generatePdfDownload, maxPortraitSegmentsPDF } from '../helpers/pdf';

import AppContext from '../AppContext';
import NavBar from '../NavBar';
import SegmentConfig from './SegmentConfig';
import SegmentStats from './SegmentStats';
import DatePicker from '../Components/DatePicker';
import PrintPageHeader from '../Components/PrintPageHeader';
import AppAlerts from '../Components/AppAlerts';
import ScrollToTop from '../Components/ScrollToTop';
import FeedbackRow from '../Components/FeedbackRow';
import CopyUrlIcon from '../Components/CopyUrlIcon';
import FiltersSelect from '../Components/Select/FiltersSelect';
import api from '../api';
import { useAppQuery, usePrevious } from '../hooks';
import { updateSession, getSession, formatFiltersForSelectDropDown, stringToSnakeCase, formatSecondsToTimeString } from '../utils';
import ChartImg from '../images/SegmentComparisonChart.png';
import LineChartImg from '../images/SegmentComparisonLineChart.png';
import SegmentsImg from '../images/SegmentComparisonSegments.png';
import SegmentsExportImg from '../images/SegmentComparisonExport.png';
import { acceptedGranularity } from '../App';
import { orgDetailsForMixpanel } from '../utils';
import moment from 'moment-timezone';

import './SegmentComparison.scss';

ReactChart.register(BarController,  BarElement, CategoryScale, Tooltip, Legend);

const chartMetricsConfig = {
  views: {
    label: 'Views',
    axisLabel: 'Count',
    colour: '#05b2da',
  },
  starters: {
    label: 'Starters',
    axisLabel: 'Count',
    colour: '#ea2e5d',
  },
  completions: {
    label: 'Completions',
    axisLabel: 'Count',
    colour: '#0267bf',
  },
  viewToStarterRate: {
    label: 'View to Starter Rate',
    axisLabel: 'Rate',
    colour: '#F0764A',
  },
  starterToCompletionRate: {
    label: 'Starter to Completion Rate',
    axisLabel: 'Rate',
    colour: '#2739c1',
  },
  viewedToCompletedRate: {
    label: 'View to Completion Rate',
    axisLabel: 'Rate',
    colour: '#820B8A',
  },
  avgDuration: {
    label: 'Average Session Duration',
    axisLabel: 'Duration',
    colour: '#FFE46F',
  },
  avgFieldReturns: {
    label: 'Average Field Returns',
    axisLabel: 'Field Returns',
    colour: '#34bfa3',
  },
};

const SegmentComparison = ({mixpanel}) => {
  const history = useHistory();
  const location = useLocation();
  const { currentUser, setUser, timeZone, currentOrg, setCurrentOrg, formsGroupedByOrg, setQuery, apiBaseUrl } = useContext(AppContext);
  const { query } = useAppQuery();
  const { time, granularity } = query || {};

  const [currentOrgs, setCurrentOrgs] = useState();
  const [comparisonsByOrgLoading, setComparisonsByOrgLoading] = useState(false);
  const [comparisonsByOrgError, setComparisonsByOrgError] = useState(null);
  const [comparisonsByOrg, setComparisonsByOrg] = useState({});
  const [comparisonsByUuid, setComparisonsByUuid] = useState({});
  const [allAmOrgs, setAllAmOrgs] = useState([]);
  const [allAmOrgsError, setAllAmOrgsError] = useState(null);
  const [selectedOrgForAm, setSelectedOrgForAm] = useState(null);
  const [selectedComparison, setSelectedComparison] = useState(null);
  const [createNewComparisonError, setCreateNewComparisonError] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedComparisonName, setEditedComparisonName] = useState('');
  const [nameSaveError, setNameSaveError] = useState(null);
  const [isConfirmingDelete, setIsConfirmingDelete] =  useState(false);
  const [deleteComparisonError, setDeleteComparisonError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [chartLoading, setChartLoading] = useState(false);
  const [chartLoadingProgress, setChartLoadingProgress] = useState(20);
  const [chartError, setChartError] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState();
  const [segments, setSegments] = useState([]);
  const [segmentsLoading, setSegmentsLoading] = useState(false);
  const [segmentsError, setSegmentsError] = useState(null);
  const [createNewSegmentError, setCreateNewSegmentError] = useState(null);
  const [deleteSegmentError, setDeleteSegmentError] = useState(null);
  const [showExportDetails, setShowExportDetails] = useState(false);
  const [exportViewsCount, setExportViewsCount] = useState(true);
  const [exportStartersCount, setExportStartersCount] = useState(true);
  const [exportCompletionsCount, setExportCompletionsCount] = useState(true);
  const [exportViewToStarterRate, setExportViewToStarterRate] = useState(true);
  const [exportStarterToCompletionRate, setExportStarterToCompletionRate] = useState(true);
  const [exportViewedToCompletedRate, setExportViewedToCompletedRate] = useState(true);
  const [exportAvgDuration, setExportAvgDuration] = useState(true);
  const [exportAvgFieldReturns, setExportAvgFieldReturns] = useState(true);
  const [segmentChangeType, setSegmentChangeType] = useState(null);
  const [reorderError, setReorderError] = useState(null);
  const [newSegmentSelectedForm, setNewSegmentSelectedForm] = useState({});
  const [availableForms, setAvailableForms] = useState([]);
  const [formsLoading, setFormsLoading] = useState(false);
  const [formsError, setFormsError] = useState(null);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersError, setFiltersError] = useState(false);
  const [saveEditedSegmentError, setSaveEditedSegmentError] = useState(null);
  const [segmentUuidsBeenUpdated, setSegmentUuidsBeenUpdated] = useState([]);
  const [showInfo, setShowInfo] = useState(null);
  const [showLineChart, setShowLineChart] = useState(null);
  const [lineChartLoading, setLineChartLoading] = useState(false);
  const [lineChartLoadingError, setLineChartLoadingError] = useState(null);
  const [lineChartProgress, setLineChartProgress] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [generatingNewComparison, setGeneratingNewComparison] = useState(null);
  const [newComparison, setNewComparison] = useState();

  // Image URL and loaded status for PDF export
  const [dataLoaded, setDataLoaded] = useState(false);
  const [chartImgUrl, setChartImgUrl] = useState(null);
  const [pdfRequested, setPdfRequested] = useState(false);
  const [allSegmentsStats, setAllSegmentsStats] = useState({});
  const [lineDataLoaded, setLineDataLoaded] = useState(false);
  const [lineChartImgUrl, setLineChartImgUrl] = useState(null);
  const [linePdfRequested, setLinePdfRequested] = useState(false);

  const [lineExportError, setLineExportError] = useState();
  const [barExportError, setBarExportError] = useState();

  const [reportVisible, setReportVisible] = useState(true);

  const lineChartAreaRef = useRef(null);
  const pageInfoAreaRef = useRef(null);
  const pageInfoIconAreaRef = useRef(null);

  const prevComparisonsByOrgLoading = usePrevious(comparisonsByOrgLoading);
  const prevSelectedComparison = usePrevious(selectedComparison);
  const prevTime = usePrevious(time);
  const prevDataLoaded = usePrevious(dataLoaded);
  const prevLineDataLoaded = usePrevious(lineDataLoaded);

  const compileComparisonQueryString = ({comparisonUuid, time, metric}) => {
    const queryParams = {
      comparison: { uuid: comparisonUuid },
      ...time && { ts: {
        start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        end: time.end.endOf('minute').clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      }},
      ...metric && { metric },
    };
    return qs.stringify(queryParams, { addQueryPrefix: true });
  };

  const initiatePdfDownload = useCallback(async () => {
    try {
      const orgName = selectedComparison && selectedComparison.orgUuid && comparisonsByOrg && comparisonsByOrg[selectedComparison.orgUuid] && comparisonsByOrg[selectedComparison.orgUuid].name;

      await generatePdfDownload({
        page: 'SegmentComparison',
        title: 'Form Segment Comparison',
        orgName,
        selectedComparison, time,
        queryString: compilePDFQueryString({comparison: selectedComparison, time}),
        data: {
          comparisonChartImgUrl: chartImgUrl, timeZone, allSegmentsStats, segments,
        },
      });
    } catch (e) {
      setBarExportError(true);
    } finally {
      setPdfRequested(false);
    }
  }, [selectedComparison, chartImgUrl, comparisonsByOrg, time, timeZone, allSegmentsStats, segments]);

  const initiateLinePdfDownload = useCallback(async () => {
    try {
      const orgName = selectedComparison && selectedComparison.orgUuid && comparisonsByOrg && comparisonsByOrg[selectedComparison.orgUuid] && comparisonsByOrg[selectedComparison.orgUuid].name;

      await generatePdfDownload({
        page: 'SegmentComparison',
        title: 'Form Segment Comparison',
        orgName,
        selectedComparison, time,
        queryString: compilePDFQueryString({comparison: selectedComparison, time, granularity}),
        data: {
          comparisonChartImgUrl: lineChartImgUrl, timeZone, metric: chartMetricsConfig[selectedMetric].label,
        },
      });
    } catch (e) {
      setLineExportError(true);
    } finally {
      setLinePdfRequested(false);
    }
  }, [selectedComparison, lineChartImgUrl, comparisonsByOrg, time, timeZone, granularity, selectedMetric]);

  const handleFiltersChange = useCallback((e) => setSelectedFilters(e || []), []);

  // Fetch user's orgs to make sure up to date
  useEffect(() => {
    if (currentUser?.uuid) (async () => {
      try {
        const { data: { organisations } } = await api.get(`/users/${currentUser.uuid}/organisations`);
        setUser((prevUser) => ({...prevUser, organisations: organisations}));
        setCurrentOrgs(organisations);
      } catch (e) {
        setComparisonsByOrgError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong loading comparisons. Please try again.');
      }
    })();
  },[currentUser?.uuid, setUser]);

  // Remove download error message
  useEffect(() => {
    if (lineExportError) setTimeout(() => {setLineExportError(null);}, 4000);
    if (barExportError) setTimeout(() => {setBarExportError(null);}, 4000);
  }, [lineExportError, barExportError]);

  // Identify the mixpanel user on page load for any subsequent tracking
  useEffect(() => {
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  // Load list of comparisons from the user's organisations
  useEffect(() => {
    const loadComparisons = async () => {
      try {
        setComparisonsByOrgLoading(true);
        setComparisonsByOrgError(null);

        const responses = await Promise.all(currentOrgs.map((org) => api.get(`/organisations/${org.uuid}/comparisons`)));

        const comparisonsByOrg = currentOrgs.reduce((acc, org) => {
          acc[org.uuid] = org;
          return acc;
        }, {});
        const comparisonsByUuid = {};

        for (const response of responses) {
          const orgUuid = response.config.url.split('/')[2];
          const comparisons = response.data.comparisons.map((c) => {
            comparisonsByUuid[c.uuid] = { ...c, orgUuid};
            return { ...c, orgUuid};
          });
          comparisonsByOrg[orgUuid].comparisons = comparisons;
        }

        setComparisonsByOrg(comparisonsByOrg);
        setComparisonsByUuid(comparisonsByUuid);
        setComparisonsByOrgLoading(false);
      } catch(e) {
        setComparisonsByOrgLoading(false);
        setComparisonsByOrgError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong loading comparisons. Please try again.');
      }
    };
    if (currentOrgs?.length) loadComparisons();
  }, [currentOrgs]);

  // Load all orgs for account managers to choose from
  useEffect(() => {
    const fetchAMOrgs = async () => {
      try {
        const { data: { organisations } } = await api.get('/organisations');
        setAllAmOrgs(organisations);
      } catch (e) {
        setAllAmOrgsError((e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong fetching all organisations for an account manager.');
      }
    };
    if (currentUser.accountManager) fetchAMOrgs();
  }, [currentUser.accountManager]);

  // An account manager selected a new org
  useEffect(() => {
    if (selectedOrgForAm) {
      setSelectedComparison(null);
      setSegments([]);
      setChartData(null);
      setCurrentOrgs([selectedOrgForAm]);
      setCurrentOrg(selectedOrgForAm);
    }
  }, [selectedOrgForAm, setCurrentOrg, formsGroupedByOrg]);

  // Once comparisons have loaded - select incoming/default
  // NB. unlike some other pages, this page only reads from params on first load. Thereafter the app-level query and session is updated manually.
  useEffect(() => {
    if (!selectedComparison && !selectedOrgForAm && Object.keys(comparisonsByOrg).length && prevComparisonsByOrgLoading && !comparisonsByOrgLoading) {
      const selectComparison = ({session: {segmentComparison}, queryParams}) => {
        const { comparison } = queryParams;
        const selectDefault = () => {
          for (let orgUuid of Object.keys(comparisonsByOrg)) {
            if (comparisonsByOrg[orgUuid].comparisons.length) return comparisonsByOrg[orgUuid].comparisons[0];
          }
        };

        // Select or generate from state
        if (queryParams['select_from_state']) {
          const { comparisonName, organisation } = location.state || {};
          const comparisonToSelect = comparisonsByOrg[organisation.uuid]?.comparisons.find(c => c.name === comparisonName);
          if (comparisonToSelect) {
            return comparisonsByUuid[comparisonToSelect.uuid];
          } else {
            setGeneratingNewComparison(true);
            return null;
          }
        }

        // Extract comparison from query params
        if (comparison && comparison.uuid) {
        // If an unknown comparison is requested still set the uuid
          return comparisonsByUuid[comparison.uuid] || {uuid: comparison.uuid};
        }

        // Extract comparison from the session if within the same app-wide org
        if (segmentComparison && segmentComparison.uuid && comparisonsByUuid[segmentComparison.uuid]) {
          if (currentOrg?.uuid === comparisonsByUuid[segmentComparison.uuid].orgUuid) return comparisonsByUuid[segmentComparison.uuid];
        }

        // Choose a comparison from the current app org
        if (currentOrg?.uuid && comparisonsByOrg[currentOrg.uuid]) return comparisonsByOrg[currentOrg.uuid].comparisons[0];

        // No current app-wide org, so select comparison from the session
        if (segmentComparison && segmentComparison.uuid) {
          return comparisonsByUuid[segmentComparison.uuid] || selectDefault();
          // An unknown uuid is not set if present in the session as users don't visibly control this so it's best to show the default comparison.
        }

        return selectDefault();
      };

      const session = getSession();
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      const incomingComparison = selectComparison({session, queryParams});
      if (!incomingComparison) return; // None assigned as generating a new one
      setSelectedComparison(incomingComparison);

      let incomingMetric = queryParams?.metric || session?.segmentComparison?.metric;
      if (incomingMetric) {
        incomingMetric = incomingMetric.replace(/([_]\w)/g, m => m[1].toUpperCase());
      }
      setSelectedMetric(((incomingMetric && chartMetricsConfig[incomingMetric]) && incomingMetric) || 'views');

      if (incomingComparison?.orgUuid && comparisonsByOrg?.[incomingComparison.orgUuid]) {
        const { name, uuid, contractType, timeZone, reportAccessEnabled } = comparisonsByOrg[incomingComparison.orgUuid];
        // Set the app-wide organisation
        if (!currentOrg || (currentOrg?.uuid && (incomingComparison.orgUuid !== currentOrg.uuid))) {
          setCurrentOrg({name, uuid, contractType, timeZone, reportAccessEnabled});
        }

        // Set the time now we know the timeZone
        let newTime;
        if ((queryParams?.ts?.start && queryParams?.ts?.end) || (session?.timeframe?.start && session?.timeframe?.end)) {
          newTime = {
            start: moment.tz(queryParams?.ts?.start || session?.timeframe?.start, timeZone),
            end: moment.tz(queryParams?.ts?.end || session?.timeframe?.end, timeZone)
          };
        } else {
          newTime =  {
            start: moment.tz(timeZone).subtract(7, 'days').startOf('day'),
            end: moment.tz(timeZone).endOf('day'),
          };
        }

        setQuery((prevQuery) => ({
          ...prevQuery, time: newTime})
        );

        mixpanel.track('Page View', { page: 'SegmentComparison',
          ...orgDetailsForMixpanel(comparisonsByOrg[incomingComparison.orgUuid]),
        });
      } else {
        mixpanel.track('Page View', { page: 'SegmentComparison' });
      }
    }
  }, [mixpanel, location.search, comparisonsByOrg, comparisonsByUuid, prevComparisonsByOrgLoading,
    comparisonsByOrgLoading, selectedComparison, selectedOrgForAm, timeZone, currentUser.accountManager,
    currentOrg, setCurrentOrg, formsGroupedByOrg, setQuery, location?.state]);

  // Selected comparison changed - so update the session
  useEffect(() => {
    if (selectedComparison) {
      const session = getSession();
      if (!session.segmentComparison) session.segmentComparison = {};
      session.segmentComparison.uuid = selectedComparison.uuid;
      updateSession(session);
    }
  }, [selectedComparison]);

  // Selected metric changed - so update the session
  useEffect(() => {
    if (selectedMetric) {
      const session = getSession();
      if (!session.segmentComparison) session.segmentComparison = {};
      session.segmentComparison.metric = stringToSnakeCase(selectedMetric);
      updateSession(session);
    }
  }, [selectedMetric]);

  // Selected comparison changed - so fetch its segments
  useEffect(() => {
    if ((selectedComparison && prevSelectedComparison && (selectedComparison.uuid !== prevSelectedComparison.uuid)) || (!prevSelectedComparison && selectedComparison)) {
      const loadSegments = async () => {
        try {
          setSegments([]);
          setSegmentsError(null);
          setSegmentsLoading(true);
          const { data: { comparison } } = await api.get(`/comparisons/${selectedComparison.uuid}`);

          if (comparison.segments.length) setSegments(comparison.segments.map((s) => ({...s, filters: s.segmentFilters}))
            .sort(({order: aOrder},{order: bOrder}) => ((aOrder === null || aOrder === '') - (bOrder === null || bOrder === '') || (aOrder > bOrder) - (aOrder < bOrder))));
          setSegmentsLoading(false);
        } catch (e) {
          setSegmentsLoading(false);
          setSegmentsError((e.response && (e.response.status === 404)) ? 'Comparison not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong loading segments. Please try again.');
        }
      };
      loadSegments();
    }
  }, [selectedComparison, prevSelectedComparison]);

  // For an account manager who has reloaded the page for an Org they're not part of
  useEffect(() => {
    if (!time && selectedComparison?.uuid && currentUser.accountManager) {
      setAllAmOrgsError('First select an Org');
    }
  }, [time, selectedComparison?.uuid, currentUser?.accountManager]);

  // Selected comparison changed - check for org change
  useEffect(() => {
    if (prevSelectedComparison?.orgUuid && selectedComparison?.orgUuid && comparisonsByOrg?.[selectedComparison.orgUuid] &&
        (prevSelectedComparison.orgUuid !== selectedComparison.orgUuid)) {
      // Set it app-wide
      const { name, uuid, contractType, timeZone, reportAccessEnabled } = comparisonsByOrg[selectedComparison.orgUuid];
      setCurrentOrg({name, uuid, contractType, timeZone, reportAccessEnabled});
    }
  }, [selectedComparison, prevSelectedComparison, comparisonsByOrg, setCurrentOrg, formsGroupedByOrg]);

  const fetchChartData = useCallback(async () => {
    const progressID = setInterval(() => setChartLoadingProgress((prevProgress) => prevProgress + 30), 50);
    try {
      setChartLoading(true);
      setChartData(null);
      setChartError(null);
      setDataLoaded(false);
      setChartImgUrl(null);

      // TODO: provide the FE with uncompiled chart data so that adding a duplicate segment could be inserted into the middle of segments
      const { data: { metrics } } = await api.get(`/comparisons/${selectedComparison.uuid}/segment_metrics`, {
        params: {
          timePeriod: {
            start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          }
        }
      });

      setChartLoadingProgress(100);

      const isData = (metrics && Object.keys(metrics).length);
      if (!isData) {
        setChartError('No data to display');
      } else {
        setChartData(metrics);
      }
    } catch (e) {
      setChartLoadingProgress(100);
      setChartError((e.response && (e.response.status === 404)) ? 'Comparison not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
    } finally {
      setChartLoading(false);
      setChartLoadingProgress(0);
      clearInterval(progressID);
      setSegmentChangeType(null);
    }
  }, [selectedComparison, time?.start, time?.end]);

  // Selected comparison has segments and time has changed so fetch chart data
  useEffect(() => {
    if (selectedComparison && prevSelectedComparison && (selectedComparison.uuid === prevSelectedComparison.uuid) &&
      ((segments.length && ((prevTime?.start && prevTime?.end && time?.start && time?.end &&
        (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
        (prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')))
      ))))) {
      fetchChartData();
    }
  }, [selectedComparison, segments.length, time, prevTime, prevSelectedComparison, fetchChartData]);

  // A segment has been edited, is new/deleted
  useEffect(() => {
    if (selectedComparison && (segmentChangeType === 'edit' || segmentChangeType === 'new' || segmentChangeType === 'delete')) {
      fetchChartData();
    }
  }, [selectedComparison, segmentChangeType, fetchChartData]);

  // Selected comparison has changed
  useEffect(() => {
    if ((selectedComparison?.uuid && prevSelectedComparison?.uuid && (selectedComparison.uuid !== prevSelectedComparison.uuid) && // Comparison changed, time already set
          (prevTime?.start && prevTime?.end && time?.start && time?.end)) ||
        (selectedComparison && !prevSelectedComparison && (time?.start && time?.end)) // Moved to page, time set
    ) {
      fetchChartData();
      // NB. for newly created comparisons, we allow the chart to fetch data as the 'selectedComparison' has changed - even though there won't be any segments.
      // When the new comparison is in a new timeZone, the time params will use the previous timezone's UTC time, but this doesn't matter as the metrics will be null anyway.
    }
  }, [selectedComparison, prevSelectedComparison, fetchChartData, time, prevTime]);

  // Fetch an organisation's forms
  useEffect(() => {
    const loadForms = async () => {
      try {
        setFormsLoading(true);
        setFormsError(null);

        const { data: { forms } } = await api.get(`/organisations/${selectedComparison.orgUuid}/forms`);
        setAvailableForms(forms.map(f => ({...f, value: f.uuid})));
        setFormsLoading(false);
      } catch (e) {
        setFormsLoading(false);
        setFormsError((e.response && (e.response.status === 404)) ? 'Comparison not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong loading forms.');
      }
    };

    if ((!prevSelectedComparison && selectedComparison && selectedComparison.orgUuid) || (selectedComparison && prevSelectedComparison && (selectedComparison.orgUuid !== prevSelectedComparison.orgUuid))) {
      loadForms();
    }

  }, [selectedComparison, prevSelectedComparison]);

  // Form selected so fetch its filters
  useEffect(() => {
    if (newSegmentSelectedForm && newSegmentSelectedForm.uuid) {
      const loadFilters = async () => {
        try {
          setFiltersLoading(true);
          setFiltersError(null);

          const { data: { attributes: filters } } = await api.get(`/data/sessions/attributes`, {
            params: {
              formUuid: newSegmentSelectedForm.uuid,
              time: {
                start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              },
            },
          });
          setFiltersLoading(false);
          setAvailableFilters(formatFiltersForSelectDropDown(filters));
        } catch (e) {
          setFiltersLoading(false);
          setFiltersError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Error when fetching filters.');
        }
      };
      loadFilters();
    }
  }, [newSegmentSelectedForm, time?.start, time?.end]);

  // Segments re-ordered so update segment order
  useEffect(() => {
    const updateSegmentOrder = async () => {
      try {
        setReorderError(null);
        await api.post('/segments/update_order', {
          segments: segments.map((s) => s.uuid),
        });
      } catch (e) {
        setReorderError((e.response && (e.response.status === 404)) ? 'Segment not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong re-ordering the segment - please try again.');
      }
    };
    if (segmentChangeType === 'reorder') updateSegmentOrder();
  }, [segments, segmentChangeType]);

  // Allow PDF to be downloaded once the chart has an image URLs and segments stats are stored
  useEffect(() => {
    if (!prevDataLoaded && chartImgUrl && Object.keys(allSegmentsStats).length) {
      if (segments.every(s => allSegmentsStats[s.uuid])) setDataLoaded(true);
    }
  },[prevDataLoaded, chartImgUrl, segments, allSegmentsStats]);

  // Allow PDF to be downloaded even when no data
  useEffect(() => {
    if (!prevDataLoaded && !chartImgUrl && !chartLoading && !chartData && !Object.keys(allSegmentsStats).length) setDataLoaded(true);
  }, [prevDataLoaded, chartImgUrl, chartLoading, allSegmentsStats, chartData]);

  // Initiate pdf download if requested, and once charts are ready
  useEffect(() => {
    if (dataLoaded && pdfRequested) initiatePdfDownload();
  }, [dataLoaded, pdfRequested, initiatePdfDownload]);

  const handleAddToAllStats = useCallback(({segmentUuid, stats}) => {
    setAllSegmentsStats((prevState) => ({...prevState, [segmentUuid]: stats}));
  }, []);

  // Allow line chart PDF to be downloaded once there is an image URL
  useEffect(() => {
    if (!prevLineDataLoaded && lineChartImgUrl) setLineDataLoaded(true);
  },[prevLineDataLoaded, lineChartImgUrl]);

  // Allow PDF to be downloaded even when no data
  useEffect(() => {
    if (!prevLineDataLoaded && !lineChartImgUrl && !lineChartLoading && lineChartLoadingError ) setLineDataLoaded(true);
  }, [prevLineDataLoaded, lineChartImgUrl, lineChartLoading, lineChartLoadingError]);

  // Initiate pdf download if requested, and once charts are ready
  useEffect(() => {
    if (lineDataLoaded && linePdfRequested) initiateLinePdfDownload();
  }, [lineDataLoaded, linePdfRequested, initiateLinePdfDownload]);

  // Fetch line chart data
  useEffect(() => {
    if (showLineChart && granularity && selectedMetric) {
      (async () => {
        const progressID = setInterval(() => setLineChartProgress((prevProgress) => prevProgress + 30), 50);
        try {
          setLineChartLoading(true);
          setLineChartData(null);
          setLineChartLoadingError(null);
          setLineDataLoaded(false);
          setLineChartImgUrl(null);

          const { data } = await api.get(`/comparisons/${selectedComparison.uuid}/segments/metric_over_time`, {
            params: {
              metric: stringToSnakeCase(selectedMetric),
              timePeriod: {
                start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              },
              granularity,
              timeZone: comparisonsByOrg[selectedComparison?.orgUuid].timeZone,
            },
          });
          setLineChartProgress(100);

          const isData = (data && data.labels.length > 0);
          if (!isData) {
            setLineChartLoadingError('No data to display');
          } else {
            const colours = ["#6A355C", "#FFAA4F", "#F45583", "#CC2C5C", "#EC521B", "#F9F871", "#6C569F", "#fabed4", "#032A95", "#458FF3", "#8C9EFB", "#39CCB7", "#609F9F", "#97919D", "#FBE44B", "#9dc284", "#175C74", "#AC87A2", "#00a0b0", "#ff3377"];
            data.datasets = data.datasets.map((dataset, i) => ({
              label: `${dataset.formLabel}${dataset.filters?.length ? ' - ' + dataset.filters?.map(({key, value}) => `${key}: ${value}`) + '  ' :'  '}`,
              data: selectedMetric === 'avgDuration' ? dataset.data.map(d => d ? d/1000 : d) : dataset.data,
              backgroundColor: colours[i],
              borderColor: colours[i],
              fill: false,
              borderWidth: 2,
              pointRadius: 1,
              pointHoverBorderWidth: 1,
            }));
            setLineChartData(data);
          }
        } catch (e) {
          setLineChartProgress(100);
          setLineChartLoadingError((e.response && (e.response.status === 404)) ? 'Comparison not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
          setLineChartLoading(false);
        } finally {
          setLineChartLoading(false);
          setLineChartProgress(0);
          clearInterval(progressID);
        }
      })();
    }

  }, [showLineChart, selectedMetric, granularity, comparisonsByOrg, selectedComparison?.orgUuid, selectedComparison?.uuid, time?.start, time?.end]);

  useEffect(() => {
    if (generatingNewComparison) {
      const { comparisonName, organisation, form, segments } = location.state || {};

      const generateNewComparison = async () => {
        setNewComparison(null);
        const progressID = setInterval(() =>
          setChartLoadingProgress((prevProgress) => prevProgress <= 100 ?
            prevProgress + 20 : prevProgress), 50);

        let newComparison;
        const orgUuid = organisation.uuid;

        try {
          setCreateNewComparisonError(null);
          const { data: { comparison: { uuid: newComparisonUuid } } } = await api.post(`/organisations/${orgUuid}/comparisons`, {name: comparisonName});

          newComparison = { uuid: newComparisonUuid, name: comparisonName, orgUuid };

          mixpanel.track('Generated Comparison', { page: 'SegmentComparison', ...orgDetailsForMixpanel(comparisonsByOrg?.[orgUuid]) });
        } catch (e) {
          setCreateNewComparisonError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong creating the new comparison. Please try again.');
        }

        if (newComparison?.uuid) {
          // Add segments
          const responses = await Promise.allSettled(segments.slice(0,20) // 20 segment limit
            .map(async (segmentFilter, i) => {
              return await api.post('/segments', {
                segment: {
                  comparison: {
                    uuid: newComparison?.uuid,
                  },
                  form: {
                    uuid: form.uuid,
                  },
                  filters: [segmentFilter],
                  order: i + 1,
                }
              });
            }));

          const rejectedResponses = responses.filter(r => r.status === 'rejected');

          setNewComparison(newComparison);
          if (rejectedResponses.length === segments.length) {
            setCreateNewComparisonError('Sorry, something went wrong creating the segments for this new comparison');
          }
        }

        clearInterval(progressID);
      };

      generateNewComparison();
    }
  }, [generatingNewComparison, location?.state, mixpanel, comparisonsByOrg]);

  useEffect(() => {
    if (generatingNewComparison && newComparison) {
      setGeneratingNewComparison(false);

      setSelectedComparison(newComparison);
      setSelectedMetric('views');

      setComparisonsByOrg({
        ...comparisonsByOrg,
        ...{[newComparison.orgUuid]: {
          ...comparisonsByOrg[newComparison.orgUuid],
          comparisons: (comparisonsByOrg[newComparison.orgUuid]?.comparisons || []).concat([newComparison]),
        }}
      });

      if (!time) {
        const timeZone = comparisonsByOrg[newComparison.orgUuid]?.timeZone;
        setQuery((prevQuery) => ({
          ...prevQuery, time: {
            start: moment.tz(timeZone).subtract(7, 'days').startOf('day'),
            end: moment.tz(timeZone).endOf('day')}
        })
        );
      }

      history.push(`?comparison[uuid]=${newComparison.uuid}`);
      setChartLoadingProgress(0);
    }
  }, [newComparison, comparisonsByOrg, generatingNewComparison, history, time, setQuery]);

  // Reference to Select dropdown to be able to programatically close it once a new comparison is created
  const comparisonSelectRef = useRef();

  const handleCreateNewComparison = async (orgUuid) => {
    const name = comparisonsByOrg[orgUuid].comparisons.length ? 'Untitled Comparison' : 'First Comparison';

    try {
      setCreateNewComparisonError(null);
      const { data: { comparison: { uuid: newComparisonUuid } } } = await api.post(`/organisations/${orgUuid}/comparisons`, {name});

      const newComparison = { uuid: newComparisonUuid, name, orgUuid };
      setSelectedComparison(newComparison);

      // Update time if the new comparison is for an org in a different timeZone
      if (selectedComparison?.ordUuid && orgUuid !== selectedComparison.orgUuid && comparisonsByOrg[orgUuid]?.timeZone && comparisonsByOrg[selectedComparison.orgUuid]?.timeZone &&
      (comparisonsByOrg[orgUuid]?.timeZone !== comparisonsByOrg[selectedComparison.orgUuid]?.timeZone)) {
        setQuery((prevQuery) => ({...prevQuery, time: {
          start: moment.tz(time.start.format('YYYY-MM-DDTHH:mm:ss'), comparisonsByOrg[orgUuid]?.timeZone),
          end: moment.tz(time.end.format('YYYY-MM-DDTHH:mm:ss'), comparisonsByOrg[orgUuid]?.timeZone),
        }}));
      }

      // Force the comparison Select to close by initiating a blur event
      comparisonSelectRef.current.blur();

      setComparisonsByOrg({
        ...comparisonsByOrg,
        ...{[orgUuid]: {
          ...comparisonsByOrg[orgUuid],
          comparisons: comparisonsByOrg[orgUuid].comparisons.concat([newComparison]),
        }}
      });
      history.push(`?comparison[uuid]=${newComparisonUuid}`);
      mixpanel.track('Created Comparison', { page: 'SegmentComparison',  ...orgDetailsForMixpanel(comparisonsByOrg?.[orgUuid]) });
    } catch (e) {
      setCreateNewComparisonError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong creating the new comparison. Please try again.');
    }
  };

  const handleComparisonChange = (c) => {
    mixpanel.track('Selected Comparison', { page: 'SegmentComparison',  ...orgDetailsForMixpanel(comparisonsByOrg?.[c.orgUuid]) });

    let newTime;
    if (!time && c.orgUuid && comparisonsByOrg?.[c.orgUuid]?.timeZone) {
      const { timeZone } = comparisonsByOrg[c.orgUuid];
      newTime = {
        start: moment.tz(timeZone).subtract(7, 'days').startOf('day'),
        end: moment.tz(timeZone).endOf('day'),
      };
    }

    history.push(compileComparisonQueryString({
      comparisonUuid: c.value,
      ...(time || newTime) && {time: {
        start: moment.tz((time || newTime).start.format('YYYY-MM-DDTHH:mm:ss'), comparisonsByOrg[c.orgUuid]?.timeZone),
        end: moment.tz((time || newTime).end.format('YYYY-MM-DDTHH:mm:ss'), comparisonsByOrg[c.orgUuid]?.timeZone),
      }},
      metric: stringToSnakeCase(selectedMetric),
    }));

    // Time isn't set on page load hook so we set here
    setQuery((prevQuery) => ({...prevQuery,
      ...(time || newTime) && {time: {
        start: moment.tz((time || newTime).start.format('YYYY-MM-DDTHH:mm:ss'), comparisonsByOrg[c.orgUuid]?.timeZone),
        end: moment.tz((time || newTime).end.format('YYYY-MM-DDTHH:mm:ss'), comparisonsByOrg[c.orgUuid]?.timeZone),
      }}}));
    setSelectedComparison({name: c.label, uuid: c.value, orgUuid: c.orgUuid});
    setShowLineChart(null);
    setShowInfo(null);
    setNewSegmentSelectedForm({});
    setSelectedFilters([]);
  };

  const handleSaveName = async () => {
    try {
      const comparisonUuid = selectedComparison.uuid;
      await api.put(`/comparisons/${comparisonUuid}`, {
        comparison: {
          name: editedComparisonName,
        }
      });
      setIsEditingName(false);
      setSelectedComparison({...selectedComparison, name: editedComparisonName});
      const orgUuid = selectedComparison.orgUuid;
      setComparisonsByOrg({
        ...comparisonsByOrg,
        ...{[orgUuid]: {
          ...comparisonsByOrg[orgUuid],
          comparisons: comparisonsByOrg[orgUuid].comparisons.map((c) => (c.uuid === comparisonUuid) ? {...c, name: editedComparisonName} : c),
        }}
      });
      mixpanel.track('Comparison Name Updated', { page: 'SegmentComparison',  ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]) });
    } catch (e) {
      setIsEditingName(false);
      setNameSaveError((e.response && (e.response.status === 422) && e.response.data && e.response.data.errors) ?
        e.response.data.errors.map((e) => `Oops, something went wrong saving the name - ${e.message}`) :
        (e.response && (e.response.status === 404)) ? 'Comparison not found' : (e.response && (e.response.status === 401)) ? 'Not logged in' :
          'Oops, something went wrong saving the name - please try again.');
    }
  };

  const handleDeleteComparison = async () => {
    try {
      setDeleteComparisonError(null);
      const comparisonUuid = selectedComparison.uuid;
      await api.delete(`/comparisons/${comparisonUuid}`);
      setIsConfirmingDelete(false);
      setSuccessMessage('Comparison deleted.');
      setSelectedComparison(null);
      const orgUuid = selectedComparison.orgUuid;
      setComparisonsByOrg({
        ...comparisonsByOrg,
        ...{[orgUuid]: {
          ...comparisonsByOrg[orgUuid],
          comparisons: comparisonsByOrg[orgUuid].comparisons.filter((c) => (c.uuid !== comparisonUuid)),
        }}
      });
      // Remove query params
      history.push('/form-segment-comparison');
      mixpanel.track('Deleted Comparison', { page: 'SegmentComparison', ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]) });
    } catch (e) {
      setIsConfirmingDelete(false);
      setDeleteComparisonError((e.response && (e.response.status === 404)) ? 'Comparison not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong deleting the comparison - please try again.');
    }
  };

  const handleCreateNewSegment = async () => {
    try {
      setCreateNewSegmentError(null);
      const { data: { segment } } = await api.post('/segments', {
        segment: {
          comparison: {
            uuid: selectedComparison.uuid,
          },
          form: {
            uuid: newSegmentSelectedForm.uuid,
          },
          filters: Object.entries(selectedFilters.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {})).map(([key, value]) => ({key, value})),
          order: (segments?.length + 1) || 1,
        }
      });

      // Deselect so the user can select a new form and filters for the next new segment
      setNewSegmentSelectedForm({});
      setSelectedFilters([]);
      setSegments(segments.concat(segment));
      setSegmentChangeType('new');
      mixpanel.track('Created Segment', { page: 'SegmentComparison', ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]) });
    } catch (e) {
      setCreateNewSegmentError((e.response && (e.response.status === 404)) ? 'Comparison not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong creating the new segment. Please try again.');
    }
  };

  const handleCreateDuplicateSegment = async ({index}) => {
    try {
      const segmentToCopy = segments[index];
      setCreateNewSegmentError(null);
      const { data: { segment } } = await api.post('/segments', {
        segment: {
          comparison: {
            uuid: selectedComparison.uuid,
          },
          form: {
            uuid: segmentToCopy.form.uuid,
          },
          filters: Object.entries(segmentToCopy.filters.reduce((acc, {key, value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {})).map(([key, value]) => ({key, value})),
          order: (segments?.length + 1) || 1, // TODO: once chart data can be sorted on the FE, this can be inserted into the middle
        }
      });

      setSegments(segments.concat(segment));
      setSegmentChangeType('new');
      mixpanel.track('Created Duplicate Segment', { page: 'SegmentComparison', ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]) });
    } catch (e) {
      setCreateNewSegmentError((e.response && (e.response.status === 404)) ? 'Comparison not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong creating the new segment. Please try again.');
    }
  };

  const handleDeleteSegment = async ({index}) => {
    try {
      const segmentUuid = segments[index].uuid;
      await api.delete(`/segments/${segmentUuid}`);
      setSegments(segments.filter((s) => s.uuid !== segmentUuid));
      setSegmentChangeType('delete');
      mixpanel.track('Deleted Segment', { page: 'SegmentComparison', ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]) });
    } catch (e) {
      setDeleteSegmentError((e.response && (e.response.status === 404)) ? 'Segment not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong deleting the segment - please try again.');
    }
  };

  const handleGranularityChange = ({target: {value}}) => {
    mixpanel.track('Selected Granularity', { page: 'SegmentComparison', ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]) });
    setQuery((prevQuery) => ({...prevQuery, granularity: value}));
  };

  // Remove any messages after being displayed for a short time
  useEffect(() => {
    if (deleteComparisonError) setTimeout(() => {setDeleteComparisonError(null);}, 4000);
    if (createNewComparisonError) setTimeout(() => {setCreateNewComparisonError(null);}, 4000);
    if (nameSaveError) setTimeout(() => {setNameSaveError(null);}, 4000);
    if (successMessage) setTimeout(() => {setSuccessMessage(null);}, 4000);
    if (comparisonsByOrgError) setTimeout(() => {setComparisonsByOrgError(null);}, 4000);
    if (reorderError) setTimeout(() => {setReorderError(null);}, 4000);
    if (allAmOrgsError) setTimeout(() => {setAllAmOrgsError(null);}, 4000);
    if (deleteSegmentError) setTimeout(() => {setDeleteSegmentError(null);}, 4000);
    if (createNewSegmentError) setTimeout(() => {setCreateNewSegmentError(null);}, 4000);

  }, [createNewSegmentError, saveEditedSegmentError, deleteComparisonError, deleteSegmentError, createNewComparisonError, nameSaveError, successMessage, allAmOrgsError, reorderError, comparisonsByOrgError]);

  const handleDateTimeRangeChange = (start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: 'SegmentComparison',
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
      ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]),
    });
    setQuery((prevQuery) => ({...prevQuery, time: {start, end}}));

    history.push(compileComparisonQueryString({
      comparisonUuid: selectedComparison.uuid,
      time: {
        start: moment.tz(start.format('YYYY-MM-DDTHH:mm:ss'), comparisonsByOrg[selectedComparison.orgUuid].timeZone),
        end: moment.tz(end.format('YYYY-MM-DDTHH:mm:ss'), comparisonsByOrg[selectedComparison.orgUuid].timeZone),
      },
      metric: stringToSnakeCase(selectedMetric),
    }));
  };

  const handleMetricChange = (metric) => {
    setSelectedMetric(metric);

    setDataLoaded(null);
    setChartImgUrl(null);
    setLineDataLoaded(null);
    setLineChartImgUrl(null);

    history.push(compileComparisonQueryString({
      comparisonUuid: selectedComparison.uuid,
      time,
      metric: stringToSnakeCase(metric),
    }));
  };

  const handleIsEditingName = () => {
    setEditedComparisonName(selectedComparison.name);
    setIsEditingName(true);
  };

  const handleSaveEditedSegment = async ({index, form, filters}) => {
    const segmentUuid = segments[index].uuid;
    setSegmentUuidsBeenUpdated((uuids) => uuids.filter(u => u !== segmentUuid));
    setSaveEditedSegmentError(null);
    try {
      const segment = segments[index];
      let greatestOrder = !segment.order && segments.filter(s => s.order)?.map(s => s.order)?.pop();

      await api.put(`/segments/${segmentUuid}`, {
        segment: {
          ...segment,
          form,
          filters: Object.entries(filters.reduce((acc, {key, label, value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(label || value);
            return acc;
          }, {})).map(([key, value]) => ({key, value})),
          ...!segment.order && {order: (greatestOrder && greatestOrder + 1) || (index + 1)},
        },
      });
      const updatedSegments = [...segments];
      updatedSegments[index] = {
        ...segment,
        form,
        filters: filters.map(({key, label, value}) => ({key, value: label || value})),
        ...!segment.order && {order: (greatestOrder && greatestOrder + 1) || (index + 1)},
      };
      setSegmentChangeType('edit');
      setSegments(updatedSegments.sort(({order: aOrder},{order: bOrder}) => ((aOrder === null || aOrder === '') - (bOrder === null || bOrder === '') || (aOrder > bOrder) - (aOrder < bOrder))));

      mixpanel.track('Edited Segment', { page: 'SegmentComparison', ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]) });
    } catch (e) {
      setSaveEditedSegmentError((e.response && (e.response.status === 404)) ? 'Segment not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Oops, something went wrong saving the segment - please try again.');
    } finally {
      setSegmentUuidsBeenUpdated((uuids) => uuids.concat([segmentUuid]));
    }
  };

  const formatChartData = (metricData) => {
    const segmentCount = metricData.length;

    return {
      labels: new Array(segmentCount).fill(''),
      datasets: [{
        label: chartMetricsConfig[selectedMetric].label,
        borderWidth: 1,
        data: (selectedMetric.includes('Rate')) ? metricData.map((m) => Number((m * 100))) : metricData,
        backgroundColor: new Array(segmentCount).fill(chartMetricsConfig[selectedMetric].colour),
      }],
    };
  };

  const formatGroupLabel = (group) => {
    const selectedInGroup = selectedFilters.filter(attr => attr.key === group.label).length;

    return (<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textTransform: "none"}}>
      <span style={{ color: '#3F4047', fontSize: 14, fontWeight: 500 }}>{group.label}</span>
      <span style={{display: 'inline-flex',alignItems: 'center'}}>
        <span style={{fontSize: 10, textAlign: 'center', paddingRight: '3px'}}>
          {selectedInGroup ? `${selectedInGroup} selected` : null}
        </span>
        <span style={{
          backgroundColor: "#EBECF0", borderRadius: "2em", color: "#172B4D", display: "inline-block", fontSize: 12,
          fontWeight: "normal", lineHeight: "0.75", minWidth: 1, padding: "0.16666666666667em 0.5em", textAlign: "center"}}>
          {group.options.length}
        </span>
      </span>
    </div>);
  };

  const comparisonFilterOption = ({ data: { orgName, orgUuid, label: comparisonLabel, value: comparisonUuid } }, currentSearchValue ) => (
    comparisonLabel.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()) ||
    (comparisonUuid && comparisonUuid.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase())) ||
    orgUuid.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()) ||
    (orgName && orgName.toLocaleLowerCase().includes(currentSearchValue.toLocaleLowerCase()))
  );

  const compileCSVExportQueryString = () => {
    let queryParams = `?comparison[uuid]=${selectedComparison.uuid}&time_zone=${timeZone}` +
    `&time_period[start]=${time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')}&time_period[end]=${time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')}`;

    if (exportViewsCount) queryParams += '&metrics[]=viewed_count';
    if (exportStartersCount) queryParams += '&metrics[]=started_count';
    if (exportCompletionsCount) queryParams += '&metrics[]=completed_count';
    if (exportViewToStarterRate) queryParams += '&metrics[]=viewed_to_started_rate';
    if (exportStarterToCompletionRate) queryParams += '&metrics[]=started_to_completed_rate';
    if (exportViewedToCompletedRate) queryParams += '&metrics[]=viewed_to_completed_rate';
    if (exportAvgDuration) queryParams += '&metrics[]=avg_duration';
    if (exportAvgFieldReturns) queryParams += '&metrics[]=avg_rtfs';

    return queryParams;
  };


  const compilePDFQueryString = ({comparison, time, granularity}) => {
    const queryParams = {
      comparison: { uuid: comparison.uuid },
      ts: {
      // The comparison will always be in sync with the timezone so we can simply use the UTC time
        start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      },
      granularity,
    };

    return qs.stringify(queryParams, { addQueryPrefix: true });
  };

  const onDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedSegments = arrayMove(segments, result.source.index, result.destination.index);
    setSegmentChangeType('reorder');
    setSegments(reorderedSegments);

    const reorderedChartData = chartData;
    setChartData({
      views: arrayMove(reorderedChartData.views, result.source.index, result.destination.index),
      starters: arrayMove(reorderedChartData.starters, result.source.index, result.destination.index),
      completions: arrayMove(reorderedChartData.completions, result.source.index, result.destination.index),
      viewToStarterRate: arrayMove(reorderedChartData.viewToStarterRate, result.source.index, result.destination.index),
      starterToCompletionRate: arrayMove(reorderedChartData.starterToCompletionRate, result.source.index, result.destination.index),
      viewedToCompletedRate: arrayMove(reorderedChartData.viewedToCompletedRate, result.source.index, result.destination.index),
      avgFieldReturns: arrayMove(reorderedChartData.avgFieldReturns, result.source.index, result.destination.index),
      avgDuration: arrayMove(reorderedChartData.avgDuration, result.source.index, result.destination.index),
    });

    mixpanel.track('Reordered Segments', { page: 'SegmentComparison', ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]) });
  };

  const handleCloseOverlay = (e) => {
    if (e.target.id !== 'segments-over-time-btn' && lineChartAreaRef.current && !lineChartAreaRef.current.contains(e.target)) {
      setShowLineChart(null);
    }
    if (pageInfoIconAreaRef.current && !pageInfoIconAreaRef.current.contains(e.target) && pageInfoAreaRef.current && !pageInfoAreaRef.current.contains(e.target)) {
      setShowInfo(null);
    }
  };

  useEffect(() => {
    if(!currentUser.accountManager) setReportVisible(!!currentOrg?.reportAccessEnabled);
  }, [currentOrg?.reportAccessEnabled, currentUser.accountManager]);

  return (
    <Container fluid className="page" id="form-segment-comparison-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Form Segment Comparison</title>
      </Helmet>
      <div className="nav-wrapper browser-only" onClick={handleCloseOverlay}>
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          {currentUser.accountManager &&
           <Col md={3} className="pt-0 pb-2 mt-1" id="organisation-select">
             <Select
               styles={{
                 control: (styles, state) => ({...styles,
                   border: '1px solid #EBEDF2',
                 }),
                 option: (styles, state) => ({...styles,
                   color: '#3f4047',
                   backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5EC" : null,
                   '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                 }),
                 menu: (styles, state) => ({...styles,
                   marginTop: '1px',
                   borderRadius: '4px',
                   border: '1px solid #EBEDF2',
                   boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                 }),
                 dropdownIndicator: (styles, state) => ({...styles,
                   cursor: 'pointer',
                   transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                   transition: 'transform .5s ease',
                 }),
               }}
               options={allAmOrgs.length > 0 ? allAmOrgs.map((org) => ({ ...org, value: org.uuid, label: org.name })) : allAmOrgsError ? [] : [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}]}
               isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
               onChange={(e) => {setShowLineChart(null); setShowInfo(null); setSelectedOrgForAm(e);}}
               placeholder="Select a different organisation..."
               value={selectedOrgForAm ? {...selectedOrgForAm, value: selectedOrgForAm.uuid} : null}
             />
           </Col>}
          <Col md={4} className="pt-0 pb-2 mt-1" id="comparison-select">
            <Select
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => {
                  return {...styles,
                    color: '#3f4047',
                    fontWeight: state.data.type === 'header' && 500,
                    backgroundColor: state.selectProps.value && (state.selectProps.value.value === state.value) ? "#E2E5Ec" : null,
                    '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                  };},
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={Object.values(comparisonsByOrg).length ? Object.values(comparisonsByOrg)
                .map(({ name: orgName, uuid: orgUuid, comparisons }) => (comparisons && comparisons.length) ? ({ label: orgName, orgUuid, options: comparisons.map(({ uuid: value, name: label }) =>
                  ({ value, label, orgUuid, orgName }))}) : ({label: orgName, orgUuid, type: 'header', selectable: false })) :
                comparisonsByOrgError ? [] : [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}]}
              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
              formatGroupLabel={formatGroupLabel}
              onChange={handleComparisonChange}
              placeholder="Select a comparison..."
              value={selectedComparison && selectedComparison.name && {...selectedComparison, label: selectedComparison.name, value: selectedComparison.uuid}}
              components={{
                ...Object.values(comparisonsByOrg).length && {MenuList: (props => {
                  return (
                    <components.MenuList {...props}>
                      {Array.isArray(props.children) && props.children.map((optGroup, i) => {
                        return (
                          <React.Fragment key={`optgroup-${i}`}>
                            {optGroup}
                            <span className="add-comparison-link" key={`add-link-${i}`} onClick={() => handleCreateNewComparison(optGroup.props.data.orgUuid)}>+ Add New Comparison</span>
                          </React.Fragment>
                        );
                      })}
                      {!Array.isArray(props.children) && props.children}
                    </components.MenuList>
                  );
                })},
              }}
              filterOption={Object.values(comparisonsByOrg).length && comparisonFilterOption}
              ref={comparisonSelectRef}
            />
          </Col>
          <Col md={5} className="pt-0 pb-2 mt-1 ps-0 d-flex align-items-center">
            {comparisonsByOrgError && <p className="error-message m-0">{comparisonsByOrgError}</p>}
            {createNewComparisonError && <p className="error-message m-0">{createNewComparisonError}</p>}
            {nameSaveError && <p className="error-message m-0">{nameSaveError}</p>}
            {deleteComparisonError && <p className="error-message m-0">{deleteComparisonError}</p>}
            {successMessage && <p className="m-0">{successMessage}</p>}
            {reorderError && <p className="error-message m-0">{reorderError}</p>}
            {allAmOrgsError && <p className="error-message m-0">{allAmOrgsError}</p>}
            {deleteSegmentError && <p className="error-message m-0">{deleteSegmentError}</p>}
            {saveEditedSegmentError && <p className="error-message m-0">{saveEditedSegmentError}</p>}
          </Col>
        </Row>
      </div>
      <ScrollToTop />
      <div className="main-content" onClick={handleCloseOverlay} data-testid="main-content-area">
        <Col className="center-column justify-content-md-center">
          <div className="pb-1">
            <FeedbackRow
              classList={['allow-scroll-under-nav']}
              mixpanel={mixpanel}
              page={'SegmentComparison'}
              org={selectedComparison?.orgUuid && comparisonsByOrg[selectedComparison.orgUuid]}
              messageContent={'Form Segment Comparison'} />
          </div>
          <AppAlerts showOrgAlerts={true} />
          <Row className="title-row g-0 browser-only">
            <Col className={`p-0 original-content ${showInfo ? 'background' : ''}`}>
              {selectedComparison && selectedComparison.orgUuid &&
                <h1 id="comparison-title" className="d-inline-flex" data-testid="page-title">
                  {`${comparisonsByOrg[selectedComparison.orgUuid]?.name} | `}
                  {!isEditingName && selectedComparison?.name}
                  {isEditingName && <>
                    <Form.Control id="comparison-name" className={`d-inline-block ${(editedComparisonName.length < 1) ? 'invalid-input' : ''}`} type="text" value={editedComparisonName} required
                      maxLength={50} htmlSize={editedComparisonName.length + 2} onChange={({target: {value}}) => setEditedComparisonName(value)} />
                    <Button variant="outline-secondary" className="cancel me-1" onClick={() => setIsEditingName(false)}>Cancel</Button>
                    <Button className="load ms-1 me-0" onClick={handleSaveName}>Save</Button>
                  </>}
                  {isConfirmingDelete && <>
                    <p id="delete-confirmation" className="d-inline-block text-muted m-0 ps-2"><small>Are you sure?</small></p>
                    <span id="comparison-delete-button">
                      <Button variant="outline-secondary" className="cancel me-1" onClick={() => setIsConfirmingDelete(false)}>Cancel</Button>
                      <Button variant="outline-danger" className="ms-1 me-0" onClick={handleDeleteComparison} data-testid="delete-comparison">Delete {selectedComparison?.name}</Button>
                    </span>
                  </>}
                  {!isEditingName && !isConfirmingDelete && <>
                    <span className="comparison-icon" onClick={handleIsEditingName}><i className="fa fa-pencil editing"/></span>
                    <span className="comparison-icon" onClick={() => setIsConfirmingDelete(true)}><i className="fa fa-trash editing"/></span></>}
                </h1>
              }
            </Col>
            <Col className={`p-0 col-auto text-end title-tooltips align-self-center original-content ${showInfo ? 'background' : ''}`}>
              {segments.length > 0 && selectedComparison && <>
                {barExportError && <p className="mb-0 pe-2 error-text d-inline-block">Error exporting data.</p>}
                <span className="icon-overlay-container">
                  <GrDocumentPdf size="20px" title="Export to PDF" className={`pdf-export-icon ${pdfRequested ? 'generating' : 'data-loaded'}`}
                    onClick={() => {setPdfRequested(true); mixpanel.track('Clicked to export', { page: 'SegmentComparison', exportType: 'PDF', ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison?.orgUuid]) });}}/>
                  {pdfRequested && <FaSpinner size="18px" className="spinning-icon" title="Generating PDF..."/>}
                </span>
                <GrDocumentCsv size="20px" title="Download data as CSV" onClick={() => setShowExportDetails(true)} className={`csv-export-icon ${segments.length > 0 ? 'data-loaded' : 'data-loading'}`}/>
              </>}
              <CopyUrlIcon
                queryString={compileComparisonQueryString({
                  comparisonUuid: selectedComparison?.uuid,
                  time,
                  metric: stringToSnakeCase(selectedMetric)})}/>
              <span ref={pageInfoIconAreaRef}>
                <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon browser-only" onClick={() => {setShowInfo(true); mixpanel.track('Clicked Segment Comparison info', { page: 'SegmentComparison',
                  ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison?.orgUuid]) });}} title="How to use"/>
              </span>

              {segments.length > 0 && selectedComparison && <>
                <Modal size="lg" show={showExportDetails} onHide={() => setShowExportDetails(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Export {selectedComparison?.name} to CSV</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" name="viewedCount"
                            id="viewedCount"
                            value="viewedCount" checked={exportViewsCount}
                            onChange={({target: {checked}}) => setExportViewsCount(checked)}/>
                          <label className="form-check-label" htmlFor="viewedCount">Views</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" name="startedCount"
                            id="startedCount"
                            value="option2" checked={exportStartersCount}
                            onChange={({target: {checked}}) => setExportStartersCount(checked)}/>
                          <label className="form-check-label" htmlFor="startedCount">Starters</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" name="completedCount"
                            id="completedCount"
                            value="option3" checked={exportCompletionsCount}
                            onChange={({target: {checked}}) => setExportCompletionsCount(checked)}/>
                          <label className="form-check-label" htmlFor="completedCount">Completions</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" name="viewedToStartedRate"
                            id="viewedToStartedRate"
                            value="option3" checked={exportViewToStarterRate}
                            onChange={({target: {checked}}) => setExportViewToStarterRate(checked)}/>
                          <label className="form-check-label" htmlFor="viewedToStartedRate">
                                View to Starter Rate</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" name="startedToCompletedRate"
                            id="startedToCompletedRate"
                            value="option3" checked={exportStarterToCompletionRate}
                            onChange={({target: {checked}}) => setExportStarterToCompletionRate(checked)}/>
                          <label className="form-check-label" htmlFor="startedToCompletedRate">
                                Starter to Completion Rate</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" name="viewedToCompletedRate"
                            id="viewedToCompletedRate"
                            value="option3" checked={exportViewedToCompletedRate}
                            onChange={({target: {checked}}) => setExportViewedToCompletedRate(checked)}/>
                          <label className="form-check-label" htmlFor="viewedToCompletedRate">
                                View to Completion Rate</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" name="avgDuration"
                            id="avgDuration"
                            value="option3" checked={exportAvgDuration}
                            onChange={({target: {checked}}) => setExportAvgDuration(checked)}/>
                          <label className="form-check-label" htmlFor="avgDuration">
                                Average Session Duration</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="checkbox" name="avgRtfs"
                            id="avgRtfs"
                            value="option3" checked={exportAvgFieldReturns}
                            onChange={({target: {checked}}) => setExportAvgFieldReturns(checked)}/>
                          <label className="form-check-label" htmlFor="avgRtfs">Average Field Returns</label>
                        </div>
                      </div>
                      <div className="col">
                        {/*Padding*/}
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {time && selectedComparison && <a className="btn btn-primary" download onClick={() => {setShowExportDetails(false); mixpanel.track('Clicked to export', { page: 'SegmentComparison', exportType: 'CSV',
                      ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison?.orgUuid])
                    });}}
                    href={`${apiBaseUrl}/export/segment_comparison${compileCSVExportQueryString()}`}
                    data-testid="export-comparison">Download CSV <i className="fa fa-download"/></a>}
                  </Modal.Footer>
                </Modal>
              </>}
            </Col>
            <div className={`page-info-card ${showInfo === null ? 'd-none' : ''} ${showInfo ? 'open' : 'closed'}`} ref={pageInfoAreaRef}>
              <div className="card-contents">
                <Row className="g-0 card-title-row">
                  <Col className="p-0">
                    <Card.Title as="h3">Form Segment Comparison</Card.Title>
                  </Col>
                  <Col className="p-0 text-end card-tooltip">
                    <VscChromeClose size="20px" className="grey-icon" onClick={() => setShowInfo(false)} title="Return to page"/>
                  </Col>
                </Row>
                <Row className="g-0 text-content">
                  <Col lg={8} className="ps-0">
                    <Card.Text className="mb-3 subtitle">This is a flexible report to compare segments and forms side-by-side.</Card.Text>
                    <Card.Text>Start by picking a time frame then add a column for a particular form and any segments you would like to view within it. You can then add further
                      columns so you can look at the data side-by-side. Or even open the <i>Segments Over Time</i> graph to see how the segments have changed.</Card.Text>
                    <figure className="img-wrapper">
                      <img id="Segment-Comparison-chart" src={ChartImg} alt="Segment-Comparison-bar-chart" className="card-info-img ms-3"></img>
                      <img id="Segment-Comparison-line-chart" src={LineChartImg} alt="Segment-Comparison-line-chart" className="card-info-img ms-3"></img>
                    </figure>
                    <Card.Text>You can change which metric you'd like to explore on both the bar and line graphs, while the raw data is displayed below in tabular form. You can export this data if you would
                      like by clicking on the 'Download data as CSV' icon in the top right.</Card.Text>
                    <figure className="img-wrapper">
                      <img id="Segment-Comparison-segments" src={SegmentsImg} alt="Segment-Comparison-segments" className="card-info-img ms-3"></img>
                      <img id="Segment-Comparison-export" src={SegmentsExportImg} alt="Segment-Comparison-export" className="card-info-img ms-3"></img>
                    </figure>
                    <Card.Text>The metric definitions are:</Card.Text>
                    <dl>
                      <dt>Views</dt>
                      <dd>How many times have people visited the form page?</dd>
                      <dt>View to Starter Rate</dt>
                      <dd>What % of people who visited the form page started to interact with it?</dd>
                      <dt>Starters</dt>
                      <dd>How many people interact with the form at all?</dd>
                      <dt>Starter to Completion Rate</dt>
                      <dd>What % of people who started interacting with the form page successfully submitted it?</dd>
                      <dt>Completions</dt>
                      <dd>How many people successfully completed the form?</dd>
                      <dt>View to Completion Rate</dt>
                      <dd>What % of people who visited the form page successfully submitted it?</dd>
                      <dt>Avg. Total Field Returns</dt>
                      <dd>On average, how many times did visitors return to a field after they first interacted with it?</dd>
                      <dt>Avg. Session Duration</dt>
                      <dd>What was the mean time spent in the form by visitors?</dd>
                    </dl>
                  </Col>
                  <Col lg={4}>
                    <div className="card-tip-box">
                      <h4 className="pb-3">How to use this and what to look for</h4>
                      <Card.Text>This visualisation is particularly useful for comparing the key performance metrics between different audience segments. For example, you can see whether visitors from different traffic sources convert at different rates.</Card.Text>
                      <Card.Text>It’s also a useful tool for multi-step form journeys as you can see how visitors progress from one step to the next and which stages they are dropping out of.</Card.Text>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>

          <Col className="chart-content p-0">
            <PrintPageHeader
              pageTitle={'Form Segment Comparison'}
              orgName={selectedComparison && selectedComparison.orgUuid && comparisonsByOrg[selectedComparison.orgUuid].name}
              formLabel={selectedComparison && selectedComparison.name}
              timeZone={timeZone}
              searchParams={selectedComparison && `?comparison[uuid]${selectedComparison.uuid}`}
            />
            {(!selectedComparison && generatingNewComparison) &&
                      <div className="progress-area" data-testid="chart-progress">
                        <ProgressBar animated now={chartLoadingProgress}/>
                      </div>}
            {selectedComparison &&
              <Card id="segment-comparison" className={`${segmentsLoading || chartLoading || generatingNewComparison ? 'full-height' : (!segmentsLoading && !segments.length) ? 'half-height': ''}`}>
                <Card.Body className={`pb-0 ${reportVisible ? '' : 'blurred-report'}`}>
                  <div className="card-vis">
                    <div className="d-flex justify-content-center">
                      <div className={`line-chart-card ${showLineChart === null ? 'd-none' : ''} ${showLineChart ? 'open' : 'closed'}`} ref={lineChartAreaRef}>
                        <div className="card-contents">
                          <Row className="g-0 card-title-row justify-content-between">
                            <Col className="p-0">
                              <Card.Title as="h3">Segments Over Time</Card.Title>
                            </Col>
                            <Col className="p-0 text-end card-tooltip">
                              {lineExportError && <p className="mb-0 pe-2 error-text d-inline-block">Error exporting data.</p>}
                              <span className="icon-overlay-container">
                                <GrDocumentPdf size="20px" title="Export to PDF" className={`pdf-export-icon ${linePdfRequested ? 'generating' : 'data-loaded'}`}
                                  onClick={() => {setLinePdfRequested(true); mixpanel.track('Clicked to export', { page: 'SegmentComparison', exportType: 'PDF', ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison?.orgUuid]) });}}/>
                                {linePdfRequested && <FaSpinner size="18px" className="spinning-icon" title="Generating PDF..."/>}
                              </span>
                              <VscChromeClose size="20px" className="grey-icon" onClick={() => {setShowLineChart(false); setLineChartData(null); setLineChartLoading(false); setLineChartLoadingError(null);}}  title="Return to page"/>
                            </Col>
                          </Row>
                          <Row className="g-0 pt-2 justify-content-between">
                            <Col className={`p-0 col-auto sc-datepicker ${lineChartLoading ? 'line-chart-loading': ''}`} id="line-chart-datepicker">
                              <DatePicker
                                startTime={time?.start}
                                endTime={time?.end}
                                onApply={handleDateTimeRangeChange}
                                timeZone={selectedComparison && selectedComparison.orgUuid && comparisonsByOrg[selectedComparison.orgUuid].timeZone}
                              />
                            </Col>
                            <Col className="p-0 col-auto d-flex card-tooltip">
                              <div id="metric-select">
                                <Select
                                  styles={{
                                    control: (styles, state) => ({...styles,
                                      border: '1px solid #EBEDF2',
                                      backgroundColor: lineChartLoading ? 'white' : null,
                                    }),
                                    singleValue: (styles, state) => ({...styles,
                                      color: '#333333',
                                    }),
                                    option: (styles, state) => {
                                      return {...styles,
                                        color: '#3f4047',
                                        backgroundColor: state.selectProps.value && (state.selectProps.value.value === state.value) ? "#E2E5Ec" : null,
                                        '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                                      };},
                                    menu: (styles, state) => ({...styles,
                                      marginTop: '1px',
                                      borderRadius: '4px',
                                      border: '1px solid #EBEDF2',
                                      boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                                    }),
                                    dropdownIndicator: (styles, state) => ({...styles,
                                      cursor: 'pointer',
                                      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                      transition: 'transform .5s ease',
                                    }),
                                  }}
                                  options={Object.entries(chartMetricsConfig).map(([key, {label}]) => ({label, value: key}))}
                                  onChange={(e) => handleMetricChange(e.value)}
                                  placeholder="Select a metric..."
                                  value={selectedMetric && chartMetricsConfig[selectedMetric] && {label: chartMetricsConfig[selectedMetric].label, value: selectedMetric}}
                                  isDisabled={lineChartLoading}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="g-0 py-2 justify-content-between">
                            <Col className="p-0 d-flex align-items-center">
                              <div className={`ps-1 granularity-buttons ${lineChartLoading ? 'line-chart-loading': ''}`}>
                                <fieldset>
                                  <FormGroup className="form-group d-flex mb-0" controlId="granularity">
                                    <Form.Label className="form-check-inline align-middle">Granularity: </Form.Label>
                                    {acceptedGranularity.map((item) => (
                                      <Form.Check inline type="radio" id={item} label={item} value={item} data-testid={`${item}-radio`} checked={item === granularity}
                                        onChange={handleGranularityChange} key={item}/>
                                    ))}
                                  </FormGroup>
                                </fieldset>
                              </div>
                            </Col>
                          </Row>
                          {lineChartData && selectedMetric ?
                            <div data-testid="line-chart-wrapper">
                              <ReactChart
                                id="segment-comparison-line-chart"
                                type="line"
                                data={lineChartData}
                                height={400}
                                options={{
                                  maintainAspectRatio: false,
                                  scales: {
                                    y: {
                                      title: {
                                        text: chartMetricsConfig[selectedMetric].axisLabel,
                                        display: true,
                                      },
                                      type: 'linear',
                                      ticks: {
                                        callback: (value, i, a) => {
                                          if (selectedMetric.includes('Rate')) return value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%';
                                          if (selectedMetric === 'avgDuration') return formatSecondsToTimeString(value);
                                          if (selectedMetric === 'avgFieldReturns') return value.toLocaleString(undefined, {maximumFractionDigits: 2});
                                          if (value > 999) return numeral(value).format('0.0a');
                                          return value;
                                        },
                                      },
                                    },
                                    x: {
                                      type: 'timeseries',
                                      time: {
                                        unit: granularity,
                                        displayFormats: {
                                          hour: lineChartData.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                                          day: 'MMM DD',
                                          week: 'MMM DD',
                                          month: 'MMM YYYY',
                                        },
                                        isoWeekday: true,
                                        tooltipFormat: granularity === 'hour' ? 'MMM D, HH:mm' : granularity === 'month' ? 'MMM YYYY' : 'MMM DD'
                                      },
                                    },
                                  },
                                  elements: {
                                    line: {
                                      tension: 0.05
                                    }
                                  },
                                  interaction: {
                                    intersect: false,
                                    mode: 'nearest',
                                  },
                                  animation: {
                                    duration: 0,
                                  },
                                  plugins: {
                                    legend: {
                                      labels: {
                                        boxWidth: 20,
                                      },
                                      padding: 20,
                                    },
                                    tooltip: {
                                      position: 'nearest',
                                      callbacks: {
                                        label: (context) => {
                                          const metricName = context.dataset.label.trim();
                                          const value = context.parsed.y.toLocaleString(undefined, {maximumFractionDigits: 2});
                                          if (selectedMetric.includes('Rate')) return `${metricName}: ${value}%`;
                                          if (selectedMetric === ('avgDuration')) return `${metricName}: ${formatSecondsToTimeString(value)}`;
                                          return `${metricName}: ${value}`;
                                        },
                                      },
                                    },
                                  },
                                }}
                              />
                            </div>
                            : lineChartLoadingError ?
                              <div className="d-flex justify-content-center flex-grow-1">
                                <p className="text-center my-auto" data-testid="line-chart-error">{lineChartLoadingError}</p>
                              </div>
                              : lineChartLoading &&
                          <div className="progress-area d-flex flex-grow-1">
                            <ProgressBar className="my-auto" animated now={lineChartProgress}/>
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                    <Row className={`g-0 justify-content-between original-content ${showLineChart ? 'background' : ''}`}>
                      <Col className="p-0 col-auto sc-datepicker" id="bar-chart-datepicker">
                        <DatePicker
                          startTime={time?.start}
                          endTime={time?.end}
                          onApply={handleDateTimeRangeChange}
                          timeZone={selectedComparison && selectedComparison.orgUuid && comparisonsByOrg[selectedComparison.orgUuid].timeZone}
                        />
                      </Col>
                      <Col className="p-0 col-auto d-flex justify-content-end">
                        {segments.length > 0 && selectedComparison &&
                          <Button className="btn-less-padding" id="segments-over-time-btn" onClick={() => {setShowLineChart(true); mixpanel.track('Viewed Segments Over Time', { page: 'SegmentComparison',
                            ...orgDetailsForMixpanel(comparisonsByOrg?.[selectedComparison.orgUuid]) });}}>Segments Over Time <AiOutlineLineChart size="20px"/></Button>
                        }
                      </Col>
                    </Row>
                    {segmentsError && <p className="error-message">{segmentsError}</p>}
                    {(segmentsLoading || chartLoading || generatingNewComparison) &&
                      <div className="progress-area" data-testid="chart-progress">
                        <ProgressBar animated now={chartLoadingProgress}/>
                      </div>}
                    <Col className={`g-0 text-center pt-2 original-content ${showInfo || showLineChart ? 'background' : ''}`} id="metric-select-area ">
                      {chartData && Object.keys(chartMetricsConfig).map((metric, i) => {
                        return (<div key={`metric-${i}`} className={`metric-selector-item ${(selectedMetric === metric) ? 'selected' : ''}`}>
                          <label htmlFor={metric}>
                            <div className="metric-select-colour-bar" style={{background: chartMetricsConfig[metric].colour}}></div>
                            <input type="radio" name="metrics" value={metric} id={metric} checked={selectedMetric === metric} onChange={({target: {value}}) => handleMetricChange(value)}/>
                            {chartMetricsConfig[metric].label}
                          </label>
                        </div>);
                      })}
                    </Col>
                    <div className={`original-content ${showInfo || showLineChart ? 'background' : ''}`} id="segment-comparison-data">
                      <div id="scrollable-comparison-container" className="d-inline-block">
                        {(chartData && selectedMetric) ? <>
                          <div id="segment-comparison-chart-wrapper" data-testid="segment-comparison-chart-wrapper">
                            <ReactChart
                              id="segment-comparison-chart"
                              type="bar"
                              data={formatChartData(chartData[selectedMetric])}
                              height={400}
                              // TODO: remove the need to prevent animation when a new segment form is selected or filters change
                              updateMode="none" // Prevent animation when a form is selected or filters change
                              options={{
                                maintainAspectRatio: false,
                                indexAxis: 'x',
                                scales: {
                                  y: {
                                    title: {
                                      text: chartMetricsConfig[selectedMetric].axisLabel,
                                      display: true,
                                    },
                                    type: 'linear',
                                    beginAtZero: true,
                                    ticks: {
                                      callback: (value, i, a) => {
                                        if (selectedMetric.includes('Rate')) return value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%';
                                        if (selectedMetric === 'avgDuration') return formatSecondsToTimeString(value);
                                        if (value > 999) return numeral(value).format('0.0a');
                                        return value;
                                      }
                                    },
                                  },
                                  x: {
                                    type: 'category',
                                    ticks: {
                                      display: false,
                                    },
                                  },
                                },
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                  tooltip: {
                                    callbacks: {
                                      label: (context) => {
                                        const metricName = context.dataset.label;
                                        const value = context.parsed.y.toLocaleString(undefined, {maximumFractionDigits: 2});
                                        if (metricName.includes('Rate')) return `${metricName}: ${value}%`;
                                        if (metricName === ('Average Session Duration')) return `${metricName}: ${formatSecondsToTimeString(value)}`;
                                        return `${metricName}: ${value}`;
                                      },
                                    },
                                  },
                                },
                              }}
                            />
                          </div> </>
                          : chartError && segments.length > 0 &&
                            <p className="text-center">{chartError}</p>
                        }
                        <div id="segments-area" className="d-flex">
                          <DragDropContext onDragEnd={onDragEnd}>
                            <div id="draggable-segments-container" className="d-flex pb-2">
                              <Droppable droppableId="droppable" direction="horizontal">
                                {(provided, snapshot) => (
                                  <div ref={provided.innerRef} style={{ display: 'flex' }} {...provided.droppableProps}>
                                    {time?.start && time?.end && segments && segments.map((segment, i) => (
                                      <Draggable key={`segment-${segment.uuid}`} draggableId={segment.uuid} index={i}>
                                        {(provided, snapshot) => (
                                          <div ref={provided.innerRef} {...provided.draggableProps}>
                                            <div className={`column segment ${i === 0 ? 'first-segment' : ''}`}>
                                              <SegmentConfig
                                                segment={segment}
                                                index={i}
                                                draggableConfig={provided}
                                                handleDeleteSegment={handleDeleteSegment}
                                                handleCreateDuplicateSegment={handleCreateDuplicateSegment}
                                                availableForms={availableForms}
                                                formsError={formsError}
                                                formsLoading={formsLoading}
                                                time={time}
                                                handleSaveEditedSegment={handleSaveEditedSegment}
                                                saveComplete={segmentUuidsBeenUpdated?.includes(segment.uuid)}
                                              />
                                              <SegmentStats
                                                segment={segment}
                                                index={i}
                                                time={time}
                                                addToAllStats={handleAddToAllStats}
                                              />
                                            </div>

                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          </DragDropContext>
                          {selectedComparison && selectedComparison.uuid && !segmentsError && ((!segmentsLoading && !segments.length) || segments.length > 0) &&
                            <div className={`column add-segment ${(!segmentsLoading && !segments.length) ? 'm-2' : ''}`}>
                              {segments.length < 20 &&
                              <div className="edit-segment browser-only">
                                <div className="edit-segment-form">
                                  <form>
                                    <label className="select-label">Form:</label>
                                    <Select
                                      id="create-segment-form-select"
                                      styles={{
                                        control: (styles, state) => ({...styles,
                                          border: '1px solid #EBEDF2',
                                        }),
                                        option: (styles, state) => ({...styles,
                                          color: '#3f4047',
                                          backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5Ec" : null,
                                          '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                                        }),
                                        menu: (styles, state) => ({...styles,
                                          marginTop: '1px',
                                          borderRadius: '4px',
                                          border: '1px solid #EBEDF2',
                                          boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                                        }),
                                        dropdownIndicator: (styles, state) => ({...styles,
                                          cursor: 'pointer',
                                          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                          transition: 'transform .5s ease',
                                        }),
                                      }}
                                      options={availableForms.length > 0 ? availableForms : formsError ? [] : formsLoading ?
                                        [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}] : []}
                                      isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
                                      onChange={(e) => setNewSegmentSelectedForm(e)}
                                      placeholder="Select a form..."
                                      value={newSegmentSelectedForm.label ? newSegmentSelectedForm : null}
                                    />
                                    <label className="select-label">Filters:</label>
                                    <div className="edit-segment-filters">
                                      <FiltersSelect
                                        id="create-segment-filters-select"
                                        form={newSegmentSelectedForm}
                                        selectedFilters={selectedFilters}
                                        filtersLoading={filtersLoading}
                                        availableFilters={availableFilters}
                                        filtersLoadingError={filtersError}
                                        handleFiltersChange={handleFiltersChange}
                                        placeholder={'Select...'}
                                        multiValueLabelFontSize={'0.9rem'}
                                        valueContainerMaxHeight={'300px'}
                                        isClearable={false} />
                                    </div>
                                  </form>
                                  <div className="text-center">
                                    <ButtonGroup id="create-segment-button">
                                      <Button className="load" disabled={!Object.keys(newSegmentSelectedForm).length} onClick={handleCreateNewSegment} data-testid="create-segment">Create Segment</Button>
                                    </ButtonGroup>
                                  </div>
                                  {createNewSegmentError && <p className="error-message mt-0">{createNewSegmentError}</p>}
                                </div>
                              </div>}
                              {segments.length >= 20 && <div className="text-center"><p className="m-0">You have created the maximum of 20 segments.</p></div>}
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>}
            <div id="hidden-charts-for-pdf-print">
              {chartData &&
                <ReactChart
                  id="segment-comparison-chart-copy"
                  type="bar"
                  data={selectedMetric && formatChartData(chartData[selectedMetric])}
                  width={1050}
                  height={segments.length > maxPortraitSegmentsPDF ? 350 : 450}
                  options={{
                    responsive: false, // Fix size for PDF export
                    indexAxis: 'x',
                    scales: {
                      y: {
                        title: {
                          display: false,
                        },
                        type: 'linear',
                        beginAtZero: true,
                        ticks: {
                          callback: (value, i, a) => {
                            if (selectedMetric.includes('Rate')) return value.toLocaleString() + '%';
                            if (selectedMetric === 'avgDuration') return value.toLocaleString() + 's';
                            if (value > 999) return numeral(value).format('0.0a');
                            return value;
                          }
                        },
                      },
                      x: {
                        type: 'category',
                        ticks: {
                          display: false,
                        },
                      },
                    },
                    animation: {
                      onComplete: () => {
                        if (!dataLoaded) {
                          const chart = Chart.getChart('segment-comparison-chart-copy');
                          if (chart) setChartImgUrl(chart.toBase64Image());
                        }
                      }
                    },
                  }}
                />}
              {lineChartData &&
                <ReactChart
                  id="segment-comparison-line-chart-copy"
                  type="line"
                  data={lineChartData}
                  width={1050}
                  height={450}
                  options={{
                    responsive: false, // Fix size for PDF export
                    scales: {
                      y: {
                        title: {
                          text: 'Count',
                          display: true,
                        },
                        type: 'linear',
                      },
                      x: {
                        type: 'timeseries',
                        time: {
                          unit: granularity,
                          displayFormats: {
                            hour: lineChartData.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                            day: 'MMM DD',
                            week: 'MMM DD',
                            month: 'MMM YYYY',
                          },
                          isoWeekday: true,
                        },
                      },
                    },
                    elements: {
                      line: {
                        tension: 0.05
                      }
                    },
                    plugins: {
                      legend: {
                        labels: {
                          boxWidth: 20
                        }
                      },
                    },
                    animation: {
                      onComplete: () => {
                        if (!lineDataLoaded) {
                          const lineChartRef = Chart.getChart('segment-comparison-line-chart-copy');
                          if (lineChartRef) setLineChartImgUrl(lineChartRef.toBase64Image());
                        }
                      }
                    },
                  }}
                />}
            </div>
          </Col>
        </Col>
      </div>
    </Container>
  );
};

export default SegmentComparison;
