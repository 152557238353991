import React, { useMemo, useState, useCallback } from 'react';
import { ReactChart } from 'chartjs-react';
import { getMatchedRangeLabel } from '../../../Components/DatePicker';

const RatesLineChart = ({ratesData, datepickerRanges, orgTime}) => {
  const [loaded, setLoaded] = useState(false);

  const datepickerRangeLabel = useCallback(() => {
    return getMatchedRangeLabel({ranges: datepickerRanges, startDate: orgTime?.start, endDate: orgTime?.end});
  }, [datepickerRanges,  orgTime?.start,  orgTime?.end]);

  const generateAnimation = useMemo(() => {
    if (!ratesData) return {};
    const totalDuration = ratesData?.chart.labels.length < 50 ? 500 : ratesData?.chart.labels.length * 10;
    const delayBetweenPoints = totalDuration / ratesData?.chart.labels.length;
    const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(0) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1]?.getProps(['y'], true).y;
    return {
      x: {
        type: 'number',
        easing: 'linear',
        duration: delayBetweenPoints,
        from: NaN, // the point is initially skipped
        delay(ctx) {
          if (ctx.type !== 'data' || ctx.xStarted) {
            return 0;
          }
          ctx.xStarted = true;
          return ctx.index * delayBetweenPoints;
        }
      },
      y: {
        type: 'number',
        easing: 'linear',
        duration: delayBetweenPoints,
        from: previousY,
        delay(ctx) {
          if (ctx.type !== 'data' || ctx.yStarted) {
            return 0;
          }
          ctx.yStarted = true;
          return ctx.index * delayBetweenPoints;
        }
      }
    };
  }, [ratesData]);

  const data = useMemo(() => {
    return {
      datasets: ratesData?.chart.datasets.filter(d => d.label === 'Starters to Completions %').map((dataset, i) => ({
        label: 'Starters to Completions',
        data: dataset.data.map((d,i) => ({x: ratesData?.chart.labels[i], y: d})),
        backgroundColor: 'rgba(244,85,131, 0.3)',
        borderColor: '#EA2E5D',
        borderWidth: 1,
        fill: true,
        pointRadius: 0,
        pointHoverBorderWidth: 1,
      })), labels: ratesData?.chart.labels
    };
  }, [ratesData]);


  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          text: 'Rate',
          display: true,
          font: {
            size: 10,
          },
          padding: {
            top: 0,
            bottom: 0,
          }
        },
        type: 'linear',
        beginAtZero: true,
        ticks: {
          callback: (value) => value.toLocaleString(undefined, {maximumFractionDigits: 2}) + '%',
        },
        suggestedMax: 100,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      x: {
        type: 'timeseries',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM DD',
          },
          isoWeekday: true,
          tooltipFormat: 'MMM DD',
        },
        title: {
          text: datepickerRangeLabel() || 'Custom time by day',
          display: true,
          font: {
            size: 10,
          },
        },
        grid: {
          display: false,
          drawBorder: true,
        },
        ticks: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: loaded ? false : {
      onComplete: () => {
        setLoaded(true);
      },
      ...generateAnimation,
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
        right: -40, // Moved the legend to right so it is central above the chart
        left: 0,
      }
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        onClick: () => null,
        labels: {
          font: {
            size: '14px',
          },
          boxWidth: 20,
        },
      },
      tooltip: {
        position: 'nearest',
        callbacks: {
          label: (context) => {
            return `Starters to Completions: ${context.parsed.y.toFixed()}%`;
          },
        },
      },
    },
  };

  return (<div className="completion-rate-chart mx-auto d-flex justify-content-center">
    <ReactChart
      id="starter-completion-rate-chart"
      type="line"
      data={data}
      options={options}
    />
  </div>
  );
};

export default RatesLineChart;
