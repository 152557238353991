import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { VscWarning, VscInfo } from "react-icons/vsc";

import AppContext from '../AppContext';

/**
 * @typedef Props
 * @prop {boolean} showOrgAlerts
 * @prop {boolean} [showBuilderOrgAlert]
 */

/**
 * @param {Props} props
 */
const AppAlerts = ({showOrgAlerts, showBuilderOrgAlert, showUntrackedFormAlert, untrackedFormUuid}) => {
  const { orgAppAlerts, setOrgAppAlerts, appAlerts, setAppAlerts, builderOrgAppAlert, setBuilderOrgAppAlert } = useContext(AppContext);

  const handleOrgAlertClose = (indexToClose) => setOrgAppAlerts(orgAppAlerts.filter((alert,i) => i !== indexToClose));

  const handleAppAlertClose = (indexToClose) => setAppAlerts(appAlerts.filter((alert,i) => i !== indexToClose));

  return ( <>
    {appAlerts?.length > 0 && appAlerts.map((alert, i) => (
      <Row className="alert-row g-0" key={`${alert.variant}${i}`}>
        <Alert dismissible={alert.dismissible ?? true} variant={alert.variant} {...(alert.variant === 'danger' || alert.variant === 'success') && {closeVariant: 'white'}}
          onClose={() => handleAppAlertClose(i)}>
          <div className="page-alert-svg-icon">{alert.variant === 'warning' || alert.variant === 'danger' ? <VscWarning size="100%"/> : alert.variant === 'info' ? <VscInfo size="100%"/> : null}</div>
          <p className="alert-text m-0" dangerouslySetInnerHTML={{__html: alert.message}}></p>
        </Alert>
      </Row>
    ))
    }
    {showOrgAlerts && orgAppAlerts && orgAppAlerts.length > 0 && orgAppAlerts.map((alert, i) => (
      <Row className={`alert-row g-0`} key={`${alert.variant}${i}`}>
        <Alert dismissible={alert.dismissible ?? true} variant={alert.variant} {...(alert.variant === 'danger' || alert.variant === 'success') && {closeVariant: 'white'}}
          onClose={() => handleOrgAlertClose(i)}>
          <div className="page-alert-svg-icon"><VscWarning size="100%"/></div>
          {alert.Component ? <alert.Component /> :
            <p className="alert-text m-0" dangerouslySetInnerHTML={{__html: alert.message}}></p>}
        </Alert>
      </Row>
    ))
    }
    {showBuilderOrgAlert && builderOrgAppAlert &&
      <Row className={`alert-row g-0 mt-2`}>
        <Alert className="stats-info-alert" dismissible={builderOrgAppAlert.dismissible} variant={builderOrgAppAlert.variant} {...(builderOrgAppAlert.variant === 'danger' || builderOrgAppAlert.variant === 'success') && {closeVariant: 'white'}}
          onClose={() => setBuilderOrgAppAlert(null)}>
          <div className="page-alert-svg-icon d-flex">{builderOrgAppAlert.variant === 'warning' || builderOrgAppAlert.variant === 'danger' ? <VscWarning size="100%"/> : builderOrgAppAlert.variant === 'info' ? <VscInfo size="100%"/> : null}</div>
          <p className="alert-text m-0" dangerouslySetInnerHTML={{__html: builderOrgAppAlert.message}}></p>
        </Alert>
      </Row>
    }
    {showUntrackedFormAlert && untrackedFormUuid &&
      <Row className={`alert-row g-0`}>
        <Alert dismissible={false} variant={'warning'}>
          <div className="page-alert-svg-icon"><VscWarning size="100%"/></div>
          <p className="alert-text m-0">Tracking has not yet started. Pleae check that the <Link to={`/forms/${untrackedFormUuid}/tracking_code`} target="_blank">Tracking Code</Link> is installed.</p>
        </Alert>
      </Row>
    }
  </>);
};

export default AppAlerts;
