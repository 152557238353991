import React, { useEffect, useState } from 'react';
import api from '../../../api.js';
import ProgressBar from 'react-bootstrap/ProgressBar';

import AbandonTable from './AbandonTable.js';

import { labelForField } from '../../../utils.js';

const AbandonChart = ({formUuid, orgTime,
  currencyCode, completionValue,
}) => {
  const [topAbandonLoading, setTopAbandonLoading] = useState();
  const [topAbandonData, setTopAbandonData] = useState();
  const [topAbandonError, setTopAbandonError] = useState();
  const [topAbandonProgress, setTopAbandonProgress] = useState(20);

  useEffect(() => {
    if (formUuid && orgTime) {
      (async () => {
        try {
          setTopAbandonLoading(true);
          setTopAbandonData(null);
          setTopAbandonError(null);
          setTopAbandonProgress(20);

          const res = await api.get('/visualisations/abandoned-fields', {
            params: {
              formUuid,
              startTime: orgTime?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              endTime: orgTime?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            },
          });

          let data = res.data;
          const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;
          data = isData && data
            .map(field => {
              field.label = field.fieldLabel;
              return {...field, label: labelForField(field)};
            })
            .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true);

          if (isData) {
            const topFields = data.sort((a,b) => b.abandonCount - a.abandonCount).slice(0,3);
            const sum = topFields?.reduce((total, field) => {
              return total + field.abandonCount;
            }, 0);

            setTopAbandonData(topFields.map(f => ({...f, percentOfTop: f.abandonCount / sum * 100})));
          }
          setTopAbandonError(!isData && 'No data to display');
        } catch (e) {
          setTopAbandonError((e.response && (e.response.status === 404)) ? 'Form not found' :
            (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
        } finally {
          setTopAbandonProgress(100);
          setTopAbandonLoading(false);
        }
      })();
    }
  }, [formUuid, orgTime]);

  // Handle progress bar
  useEffect(() => {
    let interval;
    if (topAbandonProgress && topAbandonProgress < 100) {
      interval = setInterval(() => setTopAbandonProgress((prevProgress) => prevProgress + 30), 300);
    }
    return () => clearInterval(interval);
  }, [topAbandonProgress]);

  return (<>
    {topAbandonLoading &&
        <div className="progress-area d-flex flex-grow-1 p-2 align-items-center">
          <div className="w-100">
            <ProgressBar className="my-auto" animated now={topAbandonProgress}/>
          </div>
        </div>}
    {topAbandonError && <div className="d-flex justify-content-center align-items-center error-msg-area"><p>{topAbandonError}</p></div>}
    {!topAbandonLoading && !topAbandonError && topAbandonData &&
      <AbandonTable
        data={topAbandonData}
        currencyCode={currencyCode}
        completionValue={completionValue}
      />}
  </>);
};

export default AbandonChart;
