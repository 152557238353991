import React, { useState, useMemo } from 'react';
import { ArcElement, DoughnutController, Legend } from 'chart.js';
import { ReactChart } from 'chartjs-react';
import ProgressBar from 'react-bootstrap/ProgressBar';

import ChartDataLabels from 'chartjs-plugin-datalabels';

ReactChart.register(DoughnutController, ArcElement, Legend);

const SplitAttributeDoughnutChart = ({
  vscaSplitByAttrData, vscaSplitByAttrLoading, vscaSplitByAttrProgress, vscaSplitByAttrError,
}) => {
  const mobileData = vscaSplitByAttrData?.chart.datasets.find(d => d.label === 'mobile')?.data.reduce((acc, item) => {
    acc = acc + item;
    return acc;
  }, 0) || 0;

  const desktopData = vscaSplitByAttrData?.chart.datasets.find(d => d.label === 'desktop')?.data.reduce((acc, item) => {
    acc = acc + item;
    return acc;
  }, 0) || 0;


  const tabletData = vscaSplitByAttrData?.chart.datasets.find(d => d.label === 'tablet')?.data.reduce((acc, item) => {
    acc = acc + item;
    return acc;
  }, 0) || 0;

  const [displayLabels, setDisplayLabels] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const data = useMemo(() => {
    const chartData = [mobileData, desktopData, tabletData];
    const sum = chartData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    },0);

    const fixedColours = ['#6950a5', '#F45583', '#342852'];
    const fixedMutedColours = ['#ebe7f4', '#fef1f5', '#d0c8e4'];

    return {
      labels: [
        'Mobile',
        'Desktop',
        'Tablet'
      ],
      datasets: [{
        backgroundColor: (ctx) => fixedColours[ctx.dataIndex],
        hoverBorderColor: 'white',
        data: chartData,
        datalabels: {
          labels: {
            outsideValue: {
              display: (ctx) => {
                return ctx.active
                  ? displayLabels
                  : displayLabels && 'auto';  // 'auto' will hide the label if overlaps with another
              },
              align: 'end',
              anchor: 'end',
              clamp: true,
              color: (ctx) => {
                return fixedColours[ctx.dataIndex];
              },
              textAlign: 'center',
              font: {
                weight: '400',
                size: '16px',
                lineHeight: '1.1',
              },
              padding: {
                left: 4,
                right: 4,
                top: 3,
                bottom: 2,
              },
              backgroundColor: (ctx) => {
                return fixedMutedColours[ctx.dataIndex];
              },
              borderRadius: 2,
              formatter: (value, ctx) => {
                if (!value) return null;
                const labels = ['Mobile', 'Desktop', 'Tablet'];
                const percent = value / sum * 100;
                return ctx.active
                  ? labels[ctx.dataIndex]
                  : ((percent < 1) ? null : labels[ctx.dataIndex]);
              },
              offset: 12,
            },
            insideValue: {
              display: displayLabels,
              font: {
                weight: '500',
                size: '15px',
              },
              textAlign: 'center',
              borderRadius: 2,
              color: (ctx) => {
                return ctx.active ? fixedColours[ctx.dataIndex] : 'white';
              },
              backgroundColor: (ctx) => {
                return ctx.active ? fixedMutedColours[ctx.dataIndex] : null;
              },
              formatter: (value, ctx) => {
                const percent = value / sum * 100;
                const labelsSessions = [[`${mobileData?.toLocaleString()}`, 'sessions'], [`${desktopData?.toLocaleString()}`, 'sessions'],  [`${tabletData?.toLocaleString()}`, 'sessions']];
                return ctx.active ? labelsSessions[ctx.dataIndex] : (percent < 6) ? null : percent.toFixed(0) + '%';
              },
            },
          },
        }
      }]
    };
  }, [displayLabels, mobileData, desktopData, tabletData]);

  const options = useMemo(() => ({
    onHover: (event, chartElement) => {
      // Enable cursor pointer when hovering over a chart element
      return event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    animation: loaded ? false : {
      onProgress: () => {
        if (displayLabels) setDisplayLabels(false);
      },
      onComplete: () => {
        setDisplayLabels(true);
        setLoaded(true);
      },
      animateRotate: true,
      easing: 'easeOutCirc',
      colors: {type: "color",duration: 1000,from: "transparent",}
    },
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false
      },
      legend: {
        display: false,
        onClick: () => null,
        labels: {
          font: {
            size: '14px',
          },
          boxWidth: 20,
        },
      },
      title: {
        display: false,
      },
    },
    aspectRatio: 3 / 2,
    cutout: 35,
    layout: {
      padding: {
        top: 45,
        bottom: 45,
        left: 90,
        right: 90,
      }
    },
  }), [displayLabels, loaded]);

  const plugins = useMemo(() => ([
    ChartDataLabels, // Directly add datalabels to this chart
  ]), []);

  return (<>
    {vscaSplitByAttrLoading &&
      <div className="progress-area d-flex flex-grow-1 p-2 align-items-center">
        <div className="w-100">
          <ProgressBar className="my-auto" animated now={vscaSplitByAttrProgress}/>
        </div>
      </div>}
    {vscaSplitByAttrError && <div className="d-flex justify-content-center align-items-center error-msg-area"><p>{vscaSplitByAttrError}</p></div>}
    {!vscaSplitByAttrLoading && !vscaSplitByAttrError &&
      <div className="attributes-doughnut-chart mx-auto d-flex justify-content-center">
        <ReactChart
          id="split-attribute-doughnut-chart"
          type="doughnut"
          data={data}
          width={375}
          options={options}
          plugins={plugins}
        /></div>
    }
  </>
  );
};

export default SplitAttributeDoughnutChart;
