import React, { useMemo } from 'react';
import { useTable, useFlexLayout, useGlobalFilter } from 'react-table';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { getCurrencySymbolFromCode } from '../../../utils.js';

const AbandonTable = ({
  data, currencyCode, completionValue
}) => {
  const columns = useMemo(() => [
    {Header: 'Fields', width: 150, accessor: 'label'},
    {Header: 'Lost revenue', width: 100, accessor: 'percentOfTop'},
    {Header: '% of Abandons', width: 70, accessor: 'percentOfAbandons'},
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  },
  useFlexLayout,
  useGlobalFilter,
  );

  return (
    <div className="abandon-bar-table flex-grow-1">
      <Table className="datatable mb-0 d-flex flex-column h-100" hover {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th className={`header-row-${column.id} d-flex ${column.id === 'percentOfAbandons' ? 'justify-content-center' : ''} align-items-center`}
                    {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>);
              })}
            </tr>
          ))}
        </thead>
        <tbody className="table-body flex-grow-1 d-flex flex-column" {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td className={`cell-header-${cell.column.id} d-flex flex-column justify-content-center`} {...cell.getCellProps()}>
                      {cell.column.id === 'percentOfAbandons' &&
                        <OverlayTrigger placement="top" overlay={<Popover className="dashboard-popover">
                          <Popover.Body className="fs-6">
                            <dl className="d-flex mb-0">
                              <dt className="me-2 text-nowrap">Abandon count:</dt>
                              <dd className="mb-0">{cell.row.original?.[`abandonCount`]?.toLocaleString()}</dd>
                            </dl>
                          </Popover.Body>
                        </Popover>}>
                          <span className="fs-5 pe-pointer">{cell.value?.toLocaleString(undefined, {maximumFractionDigits: 0})}%</span>
                        </OverlayTrigger>
                      }

                      {cell.column.id === 'percentOfTop' &&
                        <OverlayTrigger placement="top" overlay={<Popover className="dashboard-popover">
                          <Popover.Body className="fs-6">
                            <p className="mb-1 text-start">Using the completion value, we can attribute a proportion of the lost revenue to sessions that abandoned on this field.</p>
                            <dl className="d-flex justify-content-center mb-0">
                              <dt className="me-2 text-nowrap">Lost revenue:</dt>
                              <dd className="mb-0">{getCurrencySymbolFromCode(currencyCode)}{(cell.row.original?.['abandonCount'] * completionValue).toLocaleString()}</dd>
                            </dl>
                          </Popover.Body>
                        </Popover>}>
                          <div className="d-flex align-items-center pe-pointer">
                            <div className={`abandon-chart-grid currency-${currencyCode}`}>
                              <div className={`bar-${(cell.row.original?.['percentOfTop']).toFixed()} currency-${currencyCode}`}></div>
                            </div>
                          </div>
                        </OverlayTrigger>
                      }
                      {cell.column.id === 'label' &&
                        <OverlayTrigger placement="top" overlay={<Popover id="html-attributes-popover">
                          <Popover.Body>
                            {cell.row.original?.label &&
                          <dl className="d-flex mb-1">
                            <dt className="me-2 text-nowrap">Field Label:</dt>
                            <dd className="mb-0">{cell.row.original?.label}</dd>
                          </dl>}
                            {cell.row.original?.htmlTagName && cell.row.original?.htmlTagName !== 'null' &&
                          <dl className="d-flex mb-1">
                            <dt className="me-2 text-nowrap">HTML Tag Name:</dt>
                            <dd className="mb-0">{cell.row.original?.htmlTagName}</dd>
                          </dl>}
                            {cell.row.original?.htmlType && cell.row.originaleld?.htmlType !== 'null' &&
                          <dl className="d-flex mb-1">
                            <dt className="me-2 text-nowrap">HTML Type:</dt>
                            <dd className="mb-0">{cell.row.original?.htmlType}</dd>
                          </dl>}
                            {cell.row.original?.htmlName && cell.row.original?.htmlName !== 'null' &&
                          <dl className="d-flex mb-1">
                            <dt className="me-2 text-nowrap">HTML Name:</dt>
                            <dd className="mb-0">{cell.row.original?.htmlName}</dd>
                          </dl>}
                            {cell.row.original?.htmlId && cell.row.original?.htmlId !== 'null' &&
                          <dl className="d-flex mb-0">
                            <dt className="me-2 text-nowrap">HTML ID:</dt>
                            <dd className="mb-0">{cell.row.original?.htmlId}</dd>
                          </dl>}
                          </Popover.Body>
                        </Popover>}>
                          <span className="text-more-info abandon-field-label">{cell.render('Cell')}</span>
                        </OverlayTrigger>}
                    </td>
                  );})}
              </tr>
            );
          })}
          {!rows?.length > 0 && <tr colSpan={3}><td><span><small>There are no abandoned sessions.</small></span></td></tr>}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(AbandonTable);
