import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReplayContext } from './replayContext';
import { ReplaySessionsContextType, MetricRuleType } from '../../types/sessions';
import { Time, Form, Organisation, Filter, FilterForSelect, SelectFieldOptionType,
} from '../../types/types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import NoFormsMsg from '../../Components/NoFormsMsg';
import CopyUrlIcon from '../../Components/CopyUrlIcon';
import ChartTip from '../../Components/ChartTip';
import SessionsQueryNav from './SessionsQueryNav';
import AppContext from '../../AppContext';
import { FaCog, FaInfoCircle } from "react-icons/fa";
import {
  compileQueryString,
} from '../../utils';
import Forms from '../../forms';
import SessionsTable from './SessionsTable';
import useFetchTrackedFields from './useFetchTrackedFields';
import { VscInfo } from 'react-icons/vsc';
import api from '../../api';

const SessionsSearch = ({mixpanel}) => {
  const {
    formsGroupedByOrg,
    formsLoading,
    formsLoadingError,
    query,
    currentUser,
  } = useContext(AppContext);
  const history = useHistory();
  const { sessions, sessionsLoading, sessionsLoadingProgress, sessionsError, trackedSessionsWithoutReplays } = React.useContext(ReplayContext) as ReplaySessionsContextType;

  const {
    time,
    form,
    filters,
    sessionFilters,
    sessionOutcomes,
  }: {
    time: Time,
    form: Form,
    filters: FilterForSelect[],
    sessionOutcomes: Filter[],
    sessionFilters: {
      abandonedFieldIdentifier: string,
      interactedWithFieldIdentifiers: string[],
      metrics: MetricRuleType[],
    }
  } = query || {};

  const {
    abandonedFieldIdentifier,
  } = sessionFilters || {};

  const { availableTrackedFields }: {
   availableTrackedFields: SelectFieldOptionType[] | undefined
  } = useFetchTrackedFields();

  const [mainError, setMainError] = useState<string | null>(null);
  const outsideQuickTip = useRef<HTMLDivElement>();

  const handleBillingLinkClick = async ({uuid}) => {
    try {
      const {data: {portalSession: {url: targetUrl}}} =
      await api.post(`/organisations/${uuid}/customer_portal_sessions`); // TODO add a return_url to pass onto the stripe portal
      window.location.href = targetUrl;
    } catch (e) {
      setMainError('Sorry something went wrong accessing Billing. Please reload the page.');
    }
  };

  const SignUpMsgToDisplay = ({organisation}: {organisation: Organisation}) => {
    const { contractType, name, uuid, subscriptionStatus, plan } = organisation;

    if (contractType === 'trial') return <p className="alert-text m-0">Session replay is not currently enabled in your trial.
      Please <Link to={{pathname: `organisations/${uuid}/choose-plan`, search: '?include-replay=true', state: {organisation: {name}}}}>subscribe to a plan</Link> with it included, or
      contact <a href="mailto:support@zuko.io">support@zuko.io</a> for more information.</p>;

    if (form.organisation.type === 'AgencyClient') return <p className="alert-text m-0">Session Replay is not currently enabled in your Agency's plan. Please contact your Agency,
     or <a href="mailto:support@zuko.io">support@zuko.io</a>.</p>;

    if (subscriptionStatus && plan) { // Any org with a subscription status does or did have a plan
      // Orgs that are 'canceled' are not tracking or invoiced
      if (subscriptionStatus === 'canceled') return <p className="alert-text m-0">
        <Link to={{pathname: `organisations/${form.organisation.uuid}/choose-plan`, search: '?include-replay=true',
          state: {organisation: {name:  form.organisation.name}}}}>
        Subscribe to a plan</Link> with session replay included.</p>;

      // Expected to have an valid Stripe plan, so must be updated via Billing
      if (subscriptionStatus !== 'canceled') return <p className="alert-text m-0">Your current package does not include session replay. <span className="clickable-text" onClick={() => handleBillingLinkClick({uuid})}>
        Update your plan</span> to select one with session replay included.</p>;
    }

    // Any monthly/fixed org without a subscription status is expected to be invoiced or lapsed
    return <p className="alert-text m-0">Session replay is not currently enabled on your account.
      Please contact <a href="mailto:support@zuko.io">support@zuko.io</a> to add it to your package.</p>;
  };

  const [reportVisible, setReportVisible] = useState<boolean>(true);

  useEffect(() => {
    if(!currentUser.accountManager) setReportVisible(!!form?.organisation?.reportAccessEnabled);
  }, [currentUser.accountManager, form?.organisation?.reportAccessEnabled]);

  return (<>
    <SessionsQueryNav page={'SessionReplay'} mixpanel={mixpanel}/>
    <div className="main-content">
      <Col className="center-column justify-content-md-center">
        <div className="pb-1">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'SessionReplay'}
            org={form?.organisation}
            messageContent={'Session Replay'} />
        </div>
        <AppAlerts showOrgAlerts={true} {...(form && Object.hasOwn(form, 'tracked') && !form.tracked) && {showUntrackedFormAlert: true, untrackedFormUuid: form.uuid}} />
        <Row className="alert-row g-0 my-2">
          {form?.organisation && !form.organisation.replayEnabled  &&
            <Alert variant="info" closeVariant="white">
              <div className="alert-svg-icon my-auto"><VscInfo size="20px" className="me-2"/></div>
              <SignUpMsgToDisplay organisation={form.organisation} />
            </Alert>}
        </Row>
        {trackedSessionsWithoutReplays &&
          <Row className="alert-row g-0 mb-3">
            <Alert variant="info" closeVariant="white">
              <div className="alert-svg-icon my-auto d-flex"><VscInfo size="20px" className="me-2"/></div>
              <p className="alert-text m-0">There are no sessions with replays in this timeframe, or with selected filters. Please check that the <i>Session Replay</i> code is installed in your <Link to={`/forms/${form.uuid}/tracking_code`}>Tracking Code</Link>.</p>
            </Alert>
          </Row>}
        {mainError && <p className="error-text">{mainError}</p>}

        <Row className="title-row g-0 browser-only">
          {form?.organisation?.name && form.label && <>
            <Col className="p-0">
              <h1 id="form-title" data-testid="page-title">
                {`${form?.organisation?.name} | ${form.label} | `}
                <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
              </h1>
            </Col>
            <Col className="p-0 text-end my-auto ms-auto col-auto">
              <CopyUrlIcon
                queryString={compileQueryString({form, time, filters, sessionOutcomes, sessionFilters})}/>
              <Link to={`/forms/${form.uuid}/edit`}><FaCog size="20px" className="grey-icon" title="Form settings"/></Link>
            </Col> </>
          }
        </Row>
        {(!formsLoading && !formsLoadingError && formsGroupedByOrg && !(Forms.length > 0)) && <NoFormsMsg mixpanel={mixpanel} page={'SessionReplay'}/>}
        <Card className="mt-3">
          <Card.Body className={`d-flex flex-column ${reportVisible ? '' : 'blurred-report'}`}>
            <Row className="g-0 card-title-row">
              <Col className="p-0">
                <Card.Title as="h3">Session Replay</Card.Title>
              </Col>
              <Col className="col-auto text-end card-tooltip" ref={outsideQuickTip}>
                {(form?.organisation && form.organisation.replayEnabled) && <ChartTip
                  mixpanel={mixpanel}
                  dataLoading={sessionsLoading}
                  isData={true}
                  page={'SessionReplay'}
                  vis={'Session Replay'}>
                  <div className="popover-content text-center">
                    <h4>Quickly find problem sessions</h4>
                    <p>Apply a filter for <i>abandoned</i> sessions that <i>interacted with</i> your submit button, and has <i>returns</i>.</p>
                    <Button className="mx-0 px-3 py-2 quick-tip-internal-btn text-nowrap"
                      onClick={() => {
                        history.push(compileQueryString({
                          form,
                          time,
                          filters,
                          sessionOutcomes: [{
                            key: 'sessionOutcome',
                            value: 'abandoned',
                            label: 'Abandoned',
                          }],
                          sessionFilters: {
                            interactedWithFieldIdentifiers: [availableTrackedFields?.find(({htmlType, label}) =>
                              (htmlType && htmlType.toLowerCase() === 'submit') || (label && label.match(/submit/i)))?.identifier || null],
                            abandonedFieldIdentifier,
                            metrics: [{
                              type: 'rtf_count',
                              for: 'total-session',
                              operator: 'gte',
                              value: 1,
                            }],
                          }
                        }));
                        outsideQuickTip?.current?.click();
                      }}>Auto-apply this filter</Button>
                  </div>
                </ChartTip>}
                <OverlayTrigger placement="top" trigger={['click', 'hover']} overlay={
                  <Popover>
                    <Popover.Body className="fs-6">
                      <p className="mb-0">Session Replay allows you to watch a recording of anonymised user sessions on your forms. This will give you additional insight into user behaviour and causes of abandonment.</p>
                    </Popover.Body>
                  </Popover>}>
                  <span><FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon browser-only"/></span>
                </OverlayTrigger>
              </Col>
            </Row>
            <p>View the replays recorded for these sessions that you have filtered. Some sessions hold more than 1 replay recording.</p>
            {sessionsError ?
              <div className="d-flex justify-content-center flex-grow-1">
                <p className="text-center my-auto">{sessionsError}</p>
              </div> :
              (!form || sessionsLoading) ?
                <div className="progress-area d-flex flex-grow-1">
                  <div className="w-100">
                    <ProgressBar className="my-auto" animated now={sessionsLoadingProgress}/>
                    <p>{sessionsLoadingProgress >= 100 && 'The data is being computed, please wait...'}</p>
                  </div>
                </div> :
                (sessions && sessions.length > 0 &&
                <SessionsTable
                  data={sessions}
                  form={form}
                />)
            }
          </Card.Body>
        </Card>
      </Col>
    </div>
  </>
  );
};

export default SessionsSearch;
