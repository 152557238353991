import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import MixpanelContext from 'react-mixpanel';

import api from '../../api.js';
import SplitDoughnutChart from './Charts/SplitDoughnutChart.js';
import RatesVis from './Charts/RatesVis.js';
import SplitAttributeDoughnutChart from './Charts/SplitAttributeDoughnutChart.js';
import LiveData from './LiveData.js';
import LostRevenueCalculator from './LostRevenueCalculator.js';
import AbandonChart from './Charts/AbandonChart.js';

import { compileQueryString, orgDetailsForMixpanel } from '../../utils.js';

import { VscArrowRight } from 'react-icons/vsc';
import { FaInfoCircle } from 'react-icons/fa';

const ChartsPanel = ({isActive, sortedOn, formData, form, orgTime, org,
  formDataLoading, allFormsVscaProgress,
  handleUpdatingFormCompletion, currencyCode, completionValue,
  datepickerRanges,
}) => {
  const mixpanel = useContext(MixpanelContext);
  const [viewToCompletionRate, setViewToCompletionRate] = useState();
  const [ratesData, setRatesData] = useState();
  const [vscRatesLoading, setVscRatesLoading] = useState(true);
  const [vscRatesError, setVscRatesError] = useState(true);
  const [vscRatesProgress, setVscRatesProgress] = useState(true);
  const [formDataError, setFormDataError] = useState(null);

  const [vscaSplitByAttrData, setVscaSplitByAttrData] = useState(null);
  const [vscaSplitByAttrLoading, setVscaSplitByAttrLoading] = useState(true);
  const [vscaSplitByAttrProgress, setVscaSplitByAttrProgress] = useState(20);
  const [vscaSplitByAttrError, setVscaSplitByAttrError] = useState(null);

  const formatChartStats = (stats) => {
    for(const stat in stats) {
      stats[stat].current = stats[stat].current === null ? 'N/A' : stats[stat].current;
      stats[stat].icon = stats[stat].percentDiff > 0 ? 'up' : stats[stat].percentDiff < 0 ? 'down' : null;
      stats[stat].percentDiff = stats[stat].percentDiff !== null && (Math.round(stats[stat].percentDiff * 100) / 100).toLocaleString() + '%';
      stats[stat].title = stats[stat].current && stats[stat].previous && `${stats[stat].previous.toLocaleString()} vs ${stats[stat].current.toLocaleString()}`;
    }
    return stats;
  };

  useEffect(() => {
    if (formData?.stats?.views.current === 0) setFormDataError('No data to display');
  }, [formData]);

  useEffect(() => {
    (async () => {
      try {
        setVscRatesLoading(true);
        setVscRatesError(null);
        setRatesData(null);
        setVscRatesProgress(20);

        const { data } = await api.get('/visualisations/vsc-rates', {
          params: {
            form: {uuid: form.uuid},
            timePeriod: {start: orgTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: orgTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
            granularity: 'day', // Not using the time buckets so this value is just a default
            timeZone: org?.timeZone,
          },
        });

        const isData = (data && data.chart.labels.length > 0);
        if (!isData) {
          setVscRatesError('No data to display');
        } else {
          setViewToCompletionRate(data.stats.viewsCompletions.current);
          setRatesData(data);
        }
      } catch (e) {
        setVscRatesError((e.response && (e.response.status === 404)) ? 'Form not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      } finally {
        setVscRatesProgress(100);
        setVscRatesLoading(false);
      }
    })();
  }, [form?.uuid, orgTime, org?.timeZone]);

  useEffect(() => {
    if (form?.uuid && orgTime) (async () => {
      try {
        setVscaSplitByAttrLoading(true);
        setVscaSplitByAttrData(null);
        setVscaSplitByAttrError(null);
        setVscaSplitByAttrProgress(20);

        const { data } = await api.get('/visualisations/vsca-split-by-attribute', {
          params: {
            form: {uuid: form?.uuid},
            timePeriod: {start: orgTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: orgTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
            granularity: 'day',
            timeZone: org?.timeZone,
            metric: 'views',
            attributeKey: 'deviceType',
          },
        });
        const isData = (data && data.chart.labels.length > 0 && data.chart.datasets.length > 0);

        if (!isData) {
          setVscaSplitByAttrError('No data to display');
        } else {
          data.stats = formatChartStats(data.stats);
          setVscaSplitByAttrData(data);
        }
      } catch (e) {
        setVscaSplitByAttrError((e.response && (e.response.status === 404)) ? 'Form not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      } finally {
        setVscaSplitByAttrProgress(100);
        setVscaSplitByAttrLoading(false);
      }
    })();
  }, [form?.uuid, orgTime, org?.timeZone]);

  // Handle progress bar
  useEffect(() => {
    let interval;
    if (vscaSplitByAttrProgress && vscaSplitByAttrProgress < 100) {
      interval = setInterval(() => setVscaSplitByAttrProgress((prevProgress) => prevProgress + 30), 300);
    }
    return () => clearInterval(interval);
  }, [vscaSplitByAttrProgress]);

  useEffect(() => {
    let interval;
    if (vscRatesProgress && vscRatesProgress < 100) {
      interval = setInterval(() => setVscRatesProgress((prevProgress) => prevProgress + 30), 300);
    }
    return () => clearInterval(interval);
  }, [vscRatesProgress]);

  return (<>
    <Row className="g-0 justify-content-between">
      <Col className="p-0 mb-2 mb-xl-0 pe-xl-1" lg={12} xl={6}>
        <div className="d-flex flex-column h-100">
          <h3 className="chart-title">{form.label}</h3>
          <div className="d-flex flex-grow-1">
            <LostRevenueCalculator
              formUuid={form.uuid}
              formOrg={org}
              handleUpdatingFormCompletion={handleUpdatingFormCompletion}
              currencyCode={currencyCode}
              completionValue={completionValue}
              formCompletionValueIsSet={!!form.completionValue}
            />
          </div>
        </div>
      </Col>
      <Col className="p-0 ps-xl-1">
        <LiveData
          formUuid={form?.uuid}
          viewToCompletionRate={viewToCompletionRate}
          completionValue={completionValue}
          currencyCode={currencyCode}
          vscRatesLoading={vscRatesLoading}
        />
      </Col>
    </Row>
    <Row className="g-0 mt-2">
      <Col className="p-0 mb-2 mb-xl-0 pe-xl-1" lg={12} xl={6}>
        <div className="inner-card d-flex flex-column py-2 h-100">
          <div className="title d-flex text-center justify-content-between px-2">
            <div className="flex-grow-1 flex-basis-0"></div>
            <h4 className="mb-0">What are the session outcomes?</h4>
            <div className="flex-grow-1 flex-basis-0 d-flex justify-content-end align-items-center">
              <OverlayTrigger
                overlay={
                  <Popover className="dashboard-popover">
                    <Popover.Body className="fs-6">
                      <dl>
                        <dt>Not started</dt>
                        <dd>Visitors who viewed the form, but did not interact with it.</dd>
                        <dt>Abandons</dt>
                        <dd>Visitors who interacted with the form, but did not complete it.</dd>
                        <dt>Completions</dt>
                        <dd>Visitors who successfully completed the form.</dd>
                      </dl>
                      <p className="mb-0"><strong>Tip</strong>: Hover over the segments to see sessions count and corresponding lost revenue value.</p>
                    </Popover.Body>
                  </Popover>}>
                <span className="m-1"><FaInfoCircle size="18px" className="info"/></span>
              </OverlayTrigger>
            </div>
          </div>
          {isActive &&
          <SplitDoughnutChart
            formData={formData}
            formUuid={form?.uuid}
            sortedOn={sortedOn}
            currencyCode={currencyCode}
            completionValue={completionValue}
            formDataLoading={formDataLoading}
            allFormsVscaProgress={allFormsVscaProgress}
            formDataError={formDataError}
          />}
          <div className="d-flex justify-content-end pt-2 align-items-end flex-grow-1">
            <Link to={`${'/form_aggregate'}${(form && orgTime) ? compileQueryString({form: {...form, organisation: {timeZone: org?.timeZone}}, time: orgTime}) : ''}#vsca`}
              className="text-decoration-none d-flex align-items-center"
              onClick={() => {mixpanel.track('Clicked Go To Link', { page: 'Dashboard', vis: 'session-outcomes', ...orgDetailsForMixpanel(org) });}}>
              <Button className="go-to-page-btn d-flex" data-testid={`go-to-form-data-${form?.uuid}`}>Go to Form Data<VscArrowRight className="ms-2" size="20px"/></Button>
            </Link>
          </div>
        </div>
      </Col>
      <Col className="p-0 ps-xl-1">
        <div className="inner-card py-2 h-100 d-flex flex-column">
          <div className="title d-flex text-center justify-content-between px-2">
            <div className="flex-grow-1 flex-basis-0"></div>
            <h4 className="mb-0">Where do people abandon?</h4>
            <div className="flex-grow-1 flex-basis-0 d-flex justify-content-end align-items-center">
              <OverlayTrigger
                overlay={
                  <Popover className="dashboard-popover">
                    <Popover.Body className="fs-6">
                      <dl>
                        <dt>% of Abandons</dt>
                        <dd>The percentage of abandons on a field out of the total abandons across all fields.</dd>
                      </dl>
                      <p className="mb-0"><strong>Tip</strong>: Hover over the field metrics to see the corresponding lost revenue value and abandon count.</p>
                    </Popover.Body>
                  </Popover>}>
                <span className="m-1"><FaInfoCircle size="18px" className="info"/></span>
              </OverlayTrigger>
            </div>
          </div>
          <p className="mb-0 text-center tagline">Top 3 drop-out points</p>
          <div className="flex-grow-1 d-flex flex-column">
            <AbandonChart
              formUuid={form?.uuid}
              orgTime={orgTime}
              currencyCode={currencyCode}
              completionValue={completionValue}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Link to={`${'/field_aggregate'}${(form && orgTime) ? compileQueryString({form: {...form, organisation: {timeZone: org?.timeZone}}, time: orgTime}) : ''}`}
              className="text-decoration-none d-flex align-items-center"
              onClick={() => {mixpanel.track('Clicked Go To Link', { page: 'Dashboard', vis: 'abandon-fields', ...orgDetailsForMixpanel(org) });}}>
              <Button className="go-to-page-btn d-flex">Go to Field Data<VscArrowRight className="ms-2" size="20px"/></Button>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
    <Row className="g-0 mt-2">
      <Col className="p-0 mb-2 mb-xl-0 pe-xl-1" lg={12} xl={6}>
        <div className="inner-card py-2 h-100 d-flex flex-column">
          <div className="title d-flex text-center justify-content-between px-2">
            <div className="flex-grow-1 flex-basis-0"></div>
            <h4 className="mb-0">How often are people completing?</h4>
            <div className="flex-grow-1 flex-basis-0 d-flex justify-content-end align-items-center">
              <OverlayTrigger
                overlay={
                  <Popover className="dashboard-popover">
                    <Popover.Body className="fs-6">
                      <dl>
                        <dt>Starters to Completions</dt>
                        <dd>The % of visitors who started to interact with the form and went on to successfully submit it.</dd>
                      </dl>
                      <p className="mb-0"><strong>Tip</strong>: Use the estimated value of 5% increase in completions to help you evaluate the potential return on resources used to improve the form user experience.</p>
                    </Popover.Body>
                  </Popover>}>
                <span className="m-1"><FaInfoCircle size="18px" className="info"/></span>
              </OverlayTrigger>
            </div>
          </div>
          {isActive &&
          <RatesVis
            ratesData={ratesData}
            vscRatesLoading={vscRatesLoading}
            vscRatesError={vscRatesError}
            vscRatesProgress={vscRatesProgress}
            currentCompletions={formData?.stats.completions.current}
            completionValue={completionValue}
            currencyCode={currencyCode}
            datepickerRanges={datepickerRanges}
            orgTime={orgTime}
          />
          }
          <div className="d-flex justify-content-end pt-2">
            <Link to={`${'/form_aggregate'}${(form && orgTime) ? compileQueryString({form: {...form, organisation: {timeZone: org?.timeZone}}, time: orgTime}) : ''}#completion-path`}
              className="text-decoration-none d-flex align-items-center"
              onClick={() => {mixpanel.track('Clicked Go To Link', { page: 'Dashboard', vis: 'completion-rate', ...orgDetailsForMixpanel(org) });}}>
              <Button className="go-to-page-btn d-flex">Go to Completion Path<VscArrowRight className="ms-2" size="20px"/></Button>
            </Link>
          </div>
        </div>
      </Col>
      <Col className="p-0 ps-xl-1" lg={12} xl={6}>
        <div className="inner-card d-flex flex-column py-2 h-100">
          <div className="title d-flex text-center justify-content-between px-2">
            <div className="flex-grow-1 flex-basis-0"></div>
            <h4 className="mb-0">Which devices were used?</h4>
            <div className="flex-grow-1 flex-basis-0 d-flex justify-content-end align-items-center">
              <OverlayTrigger
                overlay={
                  <Popover className="dashboard-popover">
                    <Popover.Body className="fs-6">
                      <dl>
                        <dt>Mobile</dt>
                        <dd>All visitors who viewed the form on mobile.</dd>
                        <dt>Desktop</dt>
                        <dd>All visitors who viewed the form on desktop.</dd>
                        <dt>Tablet</dt>
                        <dd>All visitors who viewed the form on tablet.</dd>
                      </dl>
                      <p className="mb-0"><strong>Tip</strong>: Hover over the segments to see sessions count.</p>
                    </Popover.Body>
                  </Popover>}>
                <span className="m-1"><FaInfoCircle size="18px" className="info"/></span>
              </OverlayTrigger>
            </div>
          </div>
          {isActive &&
          <SplitAttributeDoughnutChart
            formData={formData}
            formUuid={form?.uuid}
            sortedOn={sortedOn}
            currencyCode={currencyCode}
            completionValue={completionValue}
            vscaSplitByAttrData={vscaSplitByAttrData}
            vscaSplitByAttrProgress={vscaSplitByAttrProgress}
            vscaSplitByAttrError={vscaSplitByAttrError}
            vscaSplitByAttrLoading={vscaSplitByAttrLoading}
          />}
          <div className="d-flex justify-content-end pt-2 align-items-end flex-grow-1 margin">
            <Link
              to={{
                pathname: `/form-segment-comparison`,
                search: '?select_from_state=true',
                state: {
                  form: {uuid: form?.uuid},
                  organisation: {uuid: org?.uuid},
                  comparisonName: `deviceType for ${form.label}`.substring(0, 50), // 50 char limit on the name,
                  segments: [{key: 'deviceType', value: 'mobile'}, {key: 'deviceType', value: 'desktop'}, {key: 'deviceType', value: 'tablet'}],
                },
              }} onClick={() => {mixpanel.track('Clicked Go To Link', { page: 'Dashboard', vis: 'device-type-split', ...orgDetailsForMixpanel(org) });}}
              className="text-decoration-none d-flex align-items-center">
              <Button className="go-to-page-btn d-flex">Go to Segment Comparison<VscArrowRight className="ms-2" size="20px"/></Button>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  </>
  );
};

export default ChartsPanel;
